import React, { Component } from 'react';
import "./style.css";
import CustomReduxForm from "../../components/materialform/customform";
import { Alert, Card, Image } from "react-bootstrap";
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { connect } from "react-redux";
import Modal from "@material-ui/core/Modal";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "@material-ui/core/Snackbar";

import { getAuthError, getCharged, getCompanyFormValues, getContactoAdd, getDiciplina, getDynamicDisable, getDynamicOptions, getEsperaCargue, getMessage, getReloadPage, getSelectBtn, getSnackBar } from "./selectors";

import {
    COMPANY_CONTACT_FORM,
    CREATE_DEPOR_POSTULATION,
    getLoginButton,
    MAIN_BUTTONS,
    LOAD_CATALOG,
    REDUX_CHANGE,
    REQUIRED_COMPANY_DATAFORM,
    REQUIRED_INFORMACION_PERSONAL,
    REQUIRED_DATOS_EPS,
    REQUIRED_DATOS_RESPONSABLE,
    REQUIRED_PADRES_EPS,
    CLOSE_MODAL,
    SELECT_DICIPLINA,
    REQUIRED_DATOS_ACUDIENTE,
    SELECT_OPORTUNIDAD_URL,
    INICIALIZE_FORM_FROM_OPORTUNIDAD,
    CONTACTO_ADD_COMPONENT,
} from './constants';
import { decode } from '../../controller/utils';


class DeportesComponent extends Component {
    componentDidMount() {
        const { loadCatalog, contactAddMessage, initializeFormOportunidad } = this.props;

        // const paramUrl = decode(window.location.search.slice(1)).split('&').reduce((acc, s) => {
        //     const [k, v] = s.split('=')
        //     return Object.assign(acc, { [k]: v })
        //   }, {})
        //   if (paramUrl.oportunidad)

        const paramUrl = window.location.search.slice(1).split('&').reduce((acc, s) => {
            const [k, v] = s.split('=')
            return Object.assign(acc, { [k]: v })
        }, {})
        // if (paramUrl.oportunidad)
        //     initializeFormOportunidad({ oportunidad: paramUrl.oportunidad })
        // else
        //     contactAddMessage({
        //         message: "El sitio al que intentas acceder contiene información incorrecta, por favor inténtelo nuevamente. En caso de presentarse este mensaje una vez más por favor comuníquese con nuestra línea de Servicio al Cliente 602 8862727 opción 2.",
        //         error: true,
        //         refresh: true
        //     })
        if (true)
            initializeFormOportunidad({ oportunidad: "27674" })
    }

    componentDidUpdate(prevProps) {

    }

    validate = (values) => {

        let errors = {
            companydataform: {},
            informacionpersonal: {},
            datosgenerales: {},
            datosresponsable: {},
            juramentoPartes: {},
            datosacudiente: {},
        };

        if (values) {
            const requiredcompanydataform = REQUIRED_COMPANY_DATAFORM;

            const requiredinformacionpersonal = REQUIRED_INFORMACION_PERSONAL;

            const requireddatosgenerales = REQUIRED_DATOS_EPS;

            const requireddatosresponsable = REQUIRED_DATOS_RESPONSABLE;

            if (!values?.juramentoPartes?.tratamientoCheck) {
                errors.juramentoPartes["tratamientoCheck"] = "Campo obligatorio";
            }

            if (!values?.juramentoPartes?.habeasData) {
                errors.juramentoPartes["habeasData"] = "Campo obligatorio";
            }

            if (values.companydataform) {
                if (values.companydataform["centroRecreacional"]) {
                    if (!values.companydataform["diciplina"]) {
                        errors.companydataform["diciplina"] = "Campo obligatorio";
                    }
                }
                requiredcompanydataform.forEach((field) => {
                    if (!values.companydataform[field]) {
                        errors.companydataform[field] = "Campo obligatorio";
                    }
                });
            }

            if (values.informacionpersonal) {
                requiredinformacionpersonal.forEach((field) => {
                    if (!values.informacionpersonal[field]) {
                        errors.informacionpersonal[field] = "Campo obligatorio";
                    }
                });
            }

            if (values.datosgenerales) {
                const cop = REQUIRED_PADRES_EPS;

                requireddatosgenerales.forEach((field) => {
                    if (!values.datosgenerales[field]) {
                        errors.datosgenerales[field] = "Campo obligatorio";
                    } else {
                        if (values.datosgenerales[field] === "1") {
                            cop.forEach(element => {
                                if (element.padre === field) {
                                    if (!values.datosgenerales[element.hijo]) {
                                        errors.datosgenerales[element.hijo] = "Campo obligatorio";
                                    }
                                }
                            });

                        }
                    }
                });
            }

            if (values.datosresponsable) {
                requireddatosresponsable.forEach((field) => {
                    if (!values.datosresponsable[field]) {
                        errors.datosresponsable[field] = "Campo obligatorio";
                    }
                });
            }

            if (
                values.datosresponsable &&
                values.datosresponsable.emailRes &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.datosresponsable.emailRes
                )
            ) {
                errors.datosresponsable.emailRes = "Correo electrónico invalido";
            }


            if (
                values.datosresponsable &&
                values.datosresponsable.cellPhoneRes &&
                values.datosresponsable.cellPhoneRes.length != 10
            ) {
                errors.datosresponsable.cellPhoneRes = "Número de teléfono invalido";
            }

            if (
                values.datosresponsable &&
                values.datosresponsable.phoneRes &&
                !(values.datosresponsable.phoneRes.length === 10 || values.datosresponsable.phoneRes.length === 7)
            ) {
                errors.datosresponsable.phoneRes = "Número de teléfono invalido";
            }


            if (
                values.datosacudiente &&
                values.datosacudiente.emailAcu &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                    values.datosacudiente.emailAcu
                )
            ) {
                errors.datosacudiente.emailAcu = "Correo electrónico invalido";
            }


            if (
                values.datosacudiente &&
                values.datosacudiente.cellPhoneAcu &&
                values.datosacudiente.cellPhoneAcu.length != 10
            ) {
                errors.datosacudiente.cellPhoneAcu = "Número de teléfono invalido";
            }

            if (
                values.datosacudiente &&
                values.datosacudiente.phoneAcu &&
                values.datosacudiente.phoneAcu.length != 7
            ) {
                errors.datosacudiente.phoneAcu = "Número de teléfono invalido";
            }

            if (
                values.informacionpersonal &&
                values.informacionpersonal.idDocumento &&
                values.informacionpersonal.idDocumento.length > 10
            ) {
                errors.informacionpersonal.idDocumento =
                    "Número de documento invalido";
            }

            if (
                values.informacionpersonal &&
                values.informacionpersonal.age &&
                (parseInt(values.informacionpersonal.age) > 110 || parseInt(values.informacionpersonal.age) < 0)
            ) {
                errors.informacionpersonal.age =
                    "Edad invalida";
            }


            let date = new Date();
            let fecha =
                (parseInt(date.getFullYear()) - 4).toString() + "-" + (parseInt(date.getMonth()) + 1).toString() + "-" + date.getDate();

            if (values.informacionpersonal && values.informacionpersonal.birthdate) {
                const fechaSis = Date.parse(values.informacionpersonal.birthdate);

                if (
                    fechaSis < Date.parse((parseInt(date.getFullYear()) - 110).toString() + "-01-01") ||
                    fechaSis > Date.parse(fecha)
                ) {
                    errors.informacionpersonal.birthdate = "Fecha de nacimiento invalida";
                }
            }
        }
        //validateForm(errors);
        return errors;
    };

    SimpleModal() {
        const { contactoAdd, error, reloadPage, closeModal } = this.props;
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="messageSucess">
                    <React.Fragment>
                        {contactoAdd.error ? (
                            <Alert className="alert danger" variant="danger">
                                {contactoAdd.message}
                            </Alert>
                        ) : (
                            <Alert className="alert success" variant="success">
                                {contactoAdd.message}
                            </Alert>
                        )}
                    </React.Fragment>
                    {reloadPage === false ?
                        <div>
                            <CircularProgress />
                        </div>
                        :
                        <div>
                            {contactoAdd.error ? contactoAdd.refresh ?
                                <Button
                                    className='buttonFinalizar'
                                    variant="text"
                                    color="default"
                                    onClick={() => window.location.replace("https://www.comfenalcovalle.com.co/")}
                                >
                                    Aceptar
                                </Button>
                                :
                                <Button
                                    className='buttonFinalizar'
                                    variant="text"
                                    color="default"
                                    onClick={() => closeModal()}
                                >
                                    Aceptar
                                </Button>
                                :
                                <Button
                                    className='buttonFinalizar'
                                    variant="text"
                                    color="default"
                                    onClick={() => window.location.replace("https://www.comfenalcovalle.com.co/")}
                                >
                                    Finalizar
                                </Button>
                            }
                        </div>
                    }
                </div>
            </Modal>
        );
    }

    showSnackBar(open, message) {
        return (
          <Snackbar open={open} autoHideDuration={1} className="ocultar">
            <Alert severity="success">{message}</Alert>
          </Snackbar>
        );
      }

    render() {
        const {
            dynamicOptions,
            dynamicDisable,
            createDeporPostulation,
            companyFormValues,
            charged,
            contactoAdd,
            showSnackBar,
            esperaCargue,
            diciplina,
        } = this.props;

        // if(diciplina !== false){
        //     import imgFutbol from diciplina.src;
        // }

        return (
            <div>
            {showSnackBar ? this.showSnackBar(
              showSnackBar,
              "Sucess"
            ) : ""}
                {esperaCargue ?
                    <div className="loading-wrapper-depor">
                        <div>
                            Cargando información, espere porfavor ...
                        </div>
                        <LinearProgress />

                    </div>
                    : ""}

                <div class="deportes-container">

                    <Card className="card">
                        <h2 className="main-title">Formulario de Inscripción Vacaciones Recreativas</h2>
                        <div class="subtitle">
                            <span>
                                <p className="llevaNegrita">¡Ya te encuentras en el último paso de tu inscripción!</p>
                                <p >¡Por favor registra los datos de tu niño o niña inscrita, su veracidad es esencial para nosotros!</p>
                                <p>Los campos señalados con el signo (*) son obligatorios.</p>
                            </span>
                        </div>
                        {!diciplina ? "" : ""
                            // <div className="btn-container">
                            //     <Image className='card-image' src={diciplina.src} />
                            //     {/* <span class="btn-text">{diciplina.name}</span> */}
                            // </div>
                        }
                        {/* <div className="btn-container">
                        <ButtonGroup className="btn-select" color="primary" aria-label="outlined primary button group">
                            <Button className="btn-title" disabled={disabledBtn.futbol} onClick={() => selectDiciplina({diciplina:"futbol"})} ><Image className='card-image' src={imgFutbol} /><span class="btn-text">Fútbol</span></Button>
                            <Button className="btn-title" disabled={disabledBtn.natacion} onClick={() => selectDiciplina({diciplina:"natacion"})} ><Image className='card-image' src={imgNatacion} /><span class="btn-text">Natación</span></Button>
                            <Button className="btn-title" disabled={disabledBtn.taekwondo} onClick={() => selectDiciplina({diciplina:"taekwondo"})} ><Image className='card-image' src={imgTaek} /><span class="btn-text">Taekwondo</span></Button>
                            <Button className="btn-title" disabled={disabledBtn.tenis} onClick={() => selectDiciplina({diciplina:"tenis"})}><Image className='card-image' src={imgTennis} /><span class="btn-text">Tenis</span></Button>
                        </ButtonGroup>
                    </div> */}
                        <Card.Body>
                            <CustomReduxForm
                                formName="formDeportesVacaciones"
                                items={COMPANY_CONTACT_FORM}
                                validations={this.validate}
                                handleSubmit={() => createDeporPostulation({
                                    body: companyFormValues,
                                    //   type: COMPANY_KEY,
                                    //   formFiles,
                                })}
                                buttons={getLoginButton(() =>
                                    createDeporPostulation({
                                        body: companyFormValues,
                                        //   type: COMPANY_KEY,
                                        //   formFiles,
                                    })
                                )}
                                dynamicOptions={dynamicOptions}
                                dynamicDisable={dynamicDisable}
                                charged={charged}
                                handleUpload={false}
                                handleRemove={false}
                            />
                        </Card.Body>
                        <div>
                            {!contactoAdd ? '' : this.SimpleModal(this.props)}
                        </div>
                    </Card>
                </div>
            </div>
        );

    }

}
const mapStateToProps = (state) => {
    return {
        dynamicOptions: getDynamicOptions(state),
        message: getMessage(state),
        diciplina: getDiciplina(state),
        companyFormValues: getCompanyFormValues(state),
        esperaCargue: getEsperaCargue(state),
        dynamicDisable: getDynamicDisable(state),
        charged: getCharged(state),
        reloadPage: getReloadPage(state),
        contactoAdd: getContactoAdd(state),
        error: getAuthError(state),
        showSnackBar: getSnackBar(state),

    };
};

const mapDispachToProps = (dispatch) => {
    return {
        createDeporPostulation: (value) => dispatch({ type: CREATE_DEPOR_POSTULATION, value }),
        selectDiciplina: (value) => dispatch({ type: SELECT_DICIPLINA, value }),
        loadCatalog: (value) => dispatch({ type: LOAD_CATALOG, value }),
        loadCatalogCitis: (value) => dispatch({ type: REDUX_CHANGE, value }),
        closeModal: (value) => dispatch({ type: CLOSE_MODAL, value }),
        selectForUrl: (value) => dispatch({ type: SELECT_OPORTUNIDAD_URL, value }),
        initializeFormOportunidad: (value) => dispatch({ type: INICIALIZE_FORM_FROM_OPORTUNIDAD, value }),
        contactAddMessage: (value) => dispatch({ type: CONTACTO_ADD_COMPONENT, value })
    };
};

export default connect(mapStateToProps, mapDispachToProps)(DeportesComponent);
