import {
  emptyJson,
} from "../../controller/utils";
import {
  SELECT_FORM_FIELD,
  SELECT_TAB,
  TAB_LOG_IN,
  AUTH_ERROR,
  AUTH_SUCCESS,
  INIT_USER_SUCCESS,
  INIT_CONTACTO_SUCCESS,
  INIT_CONTACTO_ERROR,
  UPLOAD_FILE,
  REMOVE_FILE,
  LOAD_CATALOG_SUCCESS,
  LOAD_DOCUMENT_SUCCESS,
  CHARGED_CATALOG,
  CONTACTO_ADD,
  RELOAD_PAGE,
  CARGAR_CATALOGOS,
  CARGAR_CARGO,
  DATA_AUTH,
  CALL_DINAMIC_DISABLE,
  BASE64_ADD,
  BASE64_REMOVE,
  CLOSE_MODAL,
  VALIDATE_FORM,
  CALL_DISABLED,
  SELECT_FOR_URL,
  HIDDEN_COMPONENT,
  OCULTAR_CAMPOS_FORM,
  SAVE_CONSULTA_VALIDADOR,
  DISABLE_PEOPLE_FORM,
  CHANGE_TYPE,
  MOSTRAR_CAMPOS_FORM,
  peopleContactForm,
} from './constants';

export const initialState = {
  base64Add: [],
  selectedType: {
    // fieldName: "people",
    // fieldValue: "people"
  },
  error: false,
  message: false,
  logSuccess: false,
  loadingCatalog: false,
  formInitiated: false,
  consultaValidador: {
    tipo: false,
    data: false,
    faltan: false,
  },
  defaultField: {
    // value: {
    //   fieldName: "people",
    //   fieldValue: "people"
    // }
  },
  tabSelected: `tab-${TAB_LOG_IN}`,
  formFiles: [],
  dynamicOptions: {},
  hiddenDisable:{
    form: false,
    header: false},
  dynamicDisable: OCULTAR_CAMPOS_FORM,
  // {
  //   people: true,
  //   files: false,
  //   empresa: true,
  //   socialName: false,
  //   documentCompanyType: false,
  //   companyDocument: false,
  //   companyEmail: false,

  // },
  validar:false,
  charged: false,
  reloadPage: false,
  contactoAdd: false,
  typeForm:false,
  catalogos: {},
  dataDisable: [],
  cargos: [],
  dataAuth: [],
  validForm:false,
};

const reducer = (state = initialState, action) => {
  const newState = { ...state };
  const { payload, type, value } = action;
  newState.message = false;
  newState.error = false;
  newState.loadingCatalog = false;


  switch (type) {
    case SELECT_FORM_FIELD:
      var select={
        fieldName: "people",
        fieldValue: "people"
      };
      newState.hiddenDisable.header = false;
      newState.hiddenDisable.form = false;
      if(value.event.label==='Empresa'){
        select={
          fieldName: "company",
          fieldValue: "company"
        };
        
        newState.dynamicDisable=MOSTRAR_CAMPOS_FORM;
        newState.dynamicDisable["empresas"]=true;
        newState.dynamicDisable["personas"]=false;
        newState.typeForm=peopleContactForm;
      }else{
        
        newState.dynamicDisable=OCULTAR_CAMPOS_FORM;
        newState.dynamicDisable["empresas"]=false;
        newState.dynamicDisable["personas"]=true;
      }
      newState.selectedType = select;

      return {
        ...newState
      };

    case SELECT_TAB:
      newState.tabSelected = value;

      return {
        ...newState
      };

    case AUTH_SUCCESS:
      newState.error = false;
      newState.message = payload.message;
      newState.authForm = {};
      newState.selectedForm = {};
      newState.selectedCompanyForm = {};
      newState.logSuccess = false;

      return {
        ...newState
      };

    case AUTH_ERROR:
      newState.error = true;
      newState.message = payload.message;
      newState.authForm = {};
      newState.selectedForm = {};
      newState.selectedCompanyForm = {};
      newState.logSuccess = false;

      return {
        ...newState
      };

    case INIT_USER_SUCCESS:
      newState.formInitiated = true;

      return {
        ...newState
      };

    case SAVE_CONSULTA_VALIDADOR:
      newState.consultaValidador[payload.field] = payload.data;
      return {
        ...newState
      };


    case SELECT_FOR_URL:
      const { value: { tipo } } = action
      if (tipo === "p" || tipo === "P") {
        newState.hiddenDisable.header = true;
        newState.hiddenDisable.form = false;
        newState.selectedType = {
          fieldName: "people",
          fieldValue: "people"
        };
        newState.dynamicDisable = OCULTAR_CAMPOS_FORM;
      }
      if (tipo === "e" || tipo === "E") {
        newState.hiddenDisable.header = true;
        newState.hiddenDisable.form = false;
        newState.selectedType = {
          fieldName: "company",
          fieldValue: "company"          
        }
        newState.dynamicDisable = MOSTRAR_CAMPOS_FORM;
        newState.validar=true;
      }
      


      return {
        ...newState
      };

    case INIT_CONTACTO_SUCCESS:
      newState.message = "Ticket generado exitosamente";

      return {
        ...newState
      };
    case CHANGE_TYPE:
      newState.typeForm = payload;
      newState.validar=true;

      return {
        ...newState
      };

    case DISABLE_PEOPLE_FORM:
     
      if (payload.todos) {
        newState.validar=true;
        newState.dynamicDisable = payload.data;
      } else {
        newState.dynamicDisable[payload.field] = payload.state;
      }

      return {
        ...newState
      };

    case INIT_CONTACTO_ERROR:

      newState.message = payload;
      newState.error = true;

      return {
        ...newState
      };

    case CALL_DISABLED:
      newState.dynamicDisable['files'] = payload;
      return {
        ...newState
      };

    case UPLOAD_FILE:
      if (value.event.files) {
        newState.formFiles = [];
        value.event.files.map(uploadedFile => {
          return newState.formFiles.push({
            file: uploadedFile,
            fieldName: value.fileData.name,
            fileName: uploadedFile.name,
            formName: value.fileData.formName
          })
        });
      }

      return {
        ...newState
      }

    case REMOVE_FILE:
      newState.message = true;
      if (value.event) {
        const removedFile = value.fileData.name;
        newState.formFiles.filter(item => {
          return item.fieldName === value.fileData.name
            && item.formName === value.fileData.formName
            && item.fileName !== removedFile;
        });
      }
      newState.dynamicDisable['files'] = false;

      return {
        ...newState
      }

    case LOAD_CATALOG_SUCCESS:
      newState.dynamicOptions = payload;
      return {
        ...newState
      }

    case LOAD_DOCUMENT_SUCCESS:
      newState.charged = false;
      newState.dynamicOptions[payload.field] = payload.options;

      return {
        ...newState
      }

    case CHARGED_CATALOG:
      newState.charged = payload.disable;

      return {
        ...newState
      }

    case CLOSE_MODAL:
      newState.contactoAdd = false
      return { ...newState }

    case VALIDATE_FORM:
      if(emptyJson(value.informacionpersonal) && emptyJson(value.companydataform) && emptyJson(value.companyrequestform) && emptyJson(value.informacionsolicitud) && emptyJson(value.informacionpersonalcompany)){
        newState.validForm=true;
      }else{
        if(value.informacionpersonal?.email?.length>0){
          newState.validForm=true;
        }else{          
          newState.validForm=false;
        }
      }

      return { ...newState }

    case CONTACTO_ADD:
      if (payload.error === false) {
        newState.formFiles = []
        newState.dynamicDisable['files'] = false;
      }
      newState.contactoAdd = payload;
      newState.error = payload.error;

      return {
        ...newState
      }
    case RELOAD_PAGE:
      newState.reloadPage = payload.reload;

      return {
        ...newState
      }

    case CARGAR_CATALOGOS:
      newState.catalogos = payload;
      return {
        ...newState
      }

    case CARGAR_CARGO:
      newState.cargos = payload;

      return {
        ...newState
      }

    case BASE64_ADD:
      newState.base64Add.push(payload);

      return {
        ...newState
      }

    case BASE64_REMOVE:
      newState.base64Add = payload;

      return {
        ...newState
      }

    case DATA_AUTH:
      newState.dataAuth = payload;

      return {
        ...newState
      }

    case CALL_DINAMIC_DISABLE:
      const data = payload.data;
      newState.dataDisable = payload.data;
      data.map(element => {
        newState.dynamicDisable[element] = payload.state;
      })


      return {
        ...newState
      }


    default:
      return {
        ...newState
      };
  }
};

export default reducer;
