import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import "./style.css";
import AdminComponent from './component';
import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import { AuthProvider } from '../../components/authprovider/auth';
import { Auth0Provider } from "@auth0/auth0-react";

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    localReducer: reducer,
    form: formReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);

export default class AdminPage extends Component {
    render() {
        return (
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_COLABORADOR_DOMAIN}
                clientId={process.env.REACT_APP_AUTH0_COLABORADOR_CLIENTID}
                redirectUri={`${window.location.origin}/informes`}
            >
                <Provider store={store}>
                    <Container className='main-wrapper admin' >
                        <AdminComponent urlParams={this.props.match.params} />
                    </Container>
                </Provider>
            </Auth0Provider>
        );
    }
}
