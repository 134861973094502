import React, { Component } from 'react';
import "./style.css";
import { connect } from "react-redux";


import { getCurrentTab, getDataGraph1, getDataGraph2, getDataGraph3, getEvent, getFormValid, getHiddenDisable, getShowSnackBar } from './selector';
import { LOGIN_ACTION, CHART_DATA, data, dataPhone, CARGAR_EVENT, energyConsumption, contactEstadisticForm, getLoginButton, ACTION_FILTRAR, CLOSE_MODAL_VALID, TABLE_OPTIONS_EMPRESA, TITLE_TABLE_CONTACT, TITLE_GRAF2_CONTACT, TITLE_PIE_CONTACT, TITLE_GRAF1_CONTACT, TABLE_OPTIONS_PERSONA, TITLE_TABLE_EMPRESA, deporteEstadisticForm, TABLE_OPTIONS_DEPORTES, TITLE_TABLE_DEPORTE, TITLE_PIE_DEPORTE, TITLE_GRAF1_DEPORTE } from "./constants";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import Paper from '@material-ui/core/Paper';
import {
    Chart,
    ArgumentAxis,
    ValueAxis,
    LineSeries,
    AreaSeries,
    Title,
    Legend,
    PieSeries,
} from '@devexpress/dx-react-chart-material-ui';
import { ArgumentScale, Animation } from '@devexpress/dx-react-chart';
import {
    curveCatmullRom,
    curveStep,
    line,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import { withStyles } from '@material-ui/core/styles';
import CustomReduxForm from "../../components/materialform/customform";
import { LineChartDemo } from './chartPrime';
import { DataTableCrudDemo } from './tableReact';
import { PieChartDemo } from './pierChartPrime';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';

import Snackbar from "@material-ui/core/Snackbar";


class DashRecreacion extends Component {
    componentDidMount() {
    }
    Line = props => (
        <LineSeries.Path
            {...props}
            path={line()
                .x(({ arg }) => arg)
                .y(({ val }) => val)
                .curve(curveStep)}
        />
    );

    validate = (values) => {
        let errors = {
            personalizadoFiltro: {
                fechaEnd: "",
                fechaStart: ""
            }

        };

        if (values.personalizadoFiltro) {
            if (!values.personalizadoFiltro.fechaEnd) {
                errors.personalizadoFiltro.fechaEnd = "Campo obligatorio";
            }
            else if (values.personalizadoFiltro.fechaEnd) {
                var date = new Date();
                const fechaSis = Date.parse(values.personalizadoFiltro.fechaEnd);

                if (
                    fechaSis < Date.parse("2021-06-01") ||
                    fechaSis > date
                ) {
                    errors.personalizadoFiltro.fechaEnd = "Fecha seleccionada invalida";
                }
            }

            if (!values.personalizadoFiltro.fechaStart) {
                errors.personalizadoFiltro.fechaStart = "Campo obligatorio";
            } else if (values.personalizadoFiltro.fechaStart) {
                var date = new Date();
                const fechaSis = Date.parse(values.personalizadoFiltro.fechaStart);

                if (
                    fechaSis < Date.parse("2021-06-01") ||
                    fechaSis > date
                ) {
                    errors.personalizadoFiltro.fechaStart = "Fecha seleccionada invalida";
                }
            }



        }
        return errors;
    }

    modalSubmitValid(props) {
        const { formValid, closeModalValid } = props;

        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="messageSucess">
                    <React.Fragment>
                        <Alert className="alert danger" >
                            {formValid}
                        </Alert>
                    </React.Fragment>

                    <div>
                        <Button
                            className='buttonFinalizar'
                            variant="text"
                            color="default"
                            onClick={() => closeModalValid()}
                        >
                            Continuar
                        </Button>

                    </div>

                </div>
            </Modal>
        );
    }


    showSnackBar(open, message) {
        return (
          <Snackbar open={open} autoHideDuration={1} className="ocultar">
            <Alert severity="success">{message}</Alert>
          </Snackbar>
        );
      }

    render() {
        const {
            actionFilter,
            dataGraph3,
            hiddenDisable,
            formValid,
            showSnackBar,
        } = this.props;
        return (
            <div className='contacto-wrapper'>
                {this.showSnackBar(
              showSnackBar,
              "Sucess"
            ) }
                {formValid ? this.modalSubmitValid(this.props) : ''}
                <div className="section-Contacto">
                    <React.Fragment>
                        <CustomReduxForm
                            formName="deportEstadisticForm"
                            items={deporteEstadisticForm(actionFilter
                            )}
                            submitable={false}
                            actionFilter={actionFilter}
                            validations={this.validate}
                            hiddenDisable={hiddenDisable}
                            handleSubmit={() => actionFilter()
                            }
                            buttons={false}

                        />
                    </React.Fragment>
                </div>
                 <div className="section-Contacto">
                    <LineChartDemo
                        titleLine={TITLE_GRAF1_DEPORTE}
                        dataGraph1={dataGraph3 ? dataGraph3.graph1 : false}
                    />
                </div>
                <div className="section-Contacto">
                    <PieChartDemo
                        titlePie={TITLE_PIE_DEPORTE}
                        dataGraph1={dataGraph3 ? dataGraph3.pie : []}
                    />
                </div>
                <div className="section-Contacto">
                    <DataTableCrudDemo
                        dataTable={TABLE_OPTIONS_DEPORTES}
                        dataGraph1={dataGraph3 ? dataGraph3.table : []}
                        titleTable={TITLE_TABLE_DEPORTE}
                    />

                </div>

            </div>




        );
    }
}

const mapStateToProps = state => {
    return {
        dataGraph3: getDataGraph3(state),
        hiddenDisable: getHiddenDisable(state),
        formValid: getFormValid(state),
        showSnackBar: getShowSnackBar(state),
    };
};

const mapDispachToProps = dispatch => {
    return {
        loginAction: (value) => dispatch({ type: LOGIN_ACTION, value }),
        actionFilter: (value) => dispatch({ type: ACTION_FILTRAR, value }),
        closeModalValid: (value) => dispatch({ type: CLOSE_MODAL_VALID, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(DashRecreacion);