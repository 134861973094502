import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
    prueba:process.env.NODE_ENV === 'development',
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_BASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT
};
console.log(firebaseConfig,"firebaseConfig")


const firebaseController = firebase.initializeApp(firebaseConfig);

export const firebaseDatabase = firebaseController.firestore(); 
export const firebaseAuthentication = firebaseController.auth();
export const firebaseStorage = firebaseController.storage();
export const firebaseField = firebase.firestore.FieldValue;
export const storage = firebase.storage();