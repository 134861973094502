import {
    INIT_ACTION_SUCCESS,
} from './constants';
    
export const initialState = {
    flag: false,
    responseData: false
};
    
const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type } = action;

    switch (type) {
    case INIT_ACTION_SUCCESS:
        newState.flag = true;
        newState.responseData = payload;

        return {
            ...newState
        };
        
    default:
        return {
        ...newState
        };
    }
};

export default reducer;