import { takeLatest, put, call, take } from "redux-saga/effects";
import { firebaseDatabase } from "../../controller/firebase";
import { eventChannel } from "redux-saga";

import { 
  GET_USERS_REAL_TIME,
  FILTER_USERS,
  PAGINATE_USERS,
} from './constants';

import { 
  getUsersSuccess,
  getUsersError,
  filterUserSuccess,
  paginateUserSuccess
} from './actions';

function* getUsersRealTime() { 
  const fireRealTime = {
    data: false
  };

  const reference = firebaseDatabase.collection('users').orderBy("id");
  const channel = eventChannel(emit => reference.onSnapshot(emit));

  try {
    while (true) {
      fireRealTime.data = [];
      const realData = yield take(channel);
      realData.docs.forEach(doc => {
        const { id, email, name, last_name } = doc.data();
        fireRealTime.data.push({ id, email, name, last_name });
      });

      yield put(getUsersSuccess(fireRealTime));
    }
  } catch (err) {
    yield put(getUsersError(err));
  }
}

function* filterUsers(action) { 
  const { value: {fieldValue, items} } = action;
  
  let filtered = items.filter(item => {
    let itemFiltered = false;

    Object.keys(item).map(key => {
      const keyToFilter = item[key] && typeof item[key] !== 'object' ? item[key].toString().toLowerCase() : ""; 
      itemFiltered = itemFiltered || keyToFilter.includes(fieldValue);
      return true;
    })

    return itemFiltered;
  });

  const response = {
    data: filtered
  }

  yield put(filterUserSuccess(response));
}

function* paginateUsers(action) {  
  const { users, page, itemsPerPage } = action ? action.value: {};
  const usersSize = users.length;
  let initPage = 0;
  let finalPage = users ? usersSize : 0;

  initPage = page * itemsPerPage;
  finalPage = initPage + itemsPerPage;

  if (finalPage >= usersSize) {
    finalPage = usersSize; 
  }  

  const data = users.slice(initPage, finalPage);
  const response = { data, page, itemsPerPage };

  yield put(paginateUserSuccess(response));
}


export function* watchUsers() {
  yield takeLatest(GET_USERS_REAL_TIME, getUsersRealTime);
  yield takeLatest(FILTER_USERS, filterUsers);
  yield takeLatest(PAGINATE_USERS, paginateUsers);
}
