
import {
    CALL_DINAMIC_DISABLE,
    CARGAR_CATALOGOS,
    CONTACTO_ADD,
    LOAD_CATALOG_SUCCESS,
    LOAD_DOCUMENT_SUCCESS,
    RELOAD_PAGE,
    SELECT_DICIPLINA,
    SELECT_DISIPLINA,
    UPDATE_FIELD_ATTRIBUTE,
    SAVE_BASE64_PDF,
    INIT_MODAL_ESPERA,
    CALL_DINAMIC_DISABLE_LOTE
} from './constants'

export function updateAttributes (payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function loadCatalogSuccess (payload) {
    return {
        type: LOAD_CATALOG_SUCCESS,
        payload
    };
}

export function cargarCatalogos (payload) {
    return {
        type: CARGAR_CATALOGOS,
        payload
    };
}
export function loadDocumentSuccess (payload) {
    return {
        type: LOAD_DOCUMENT_SUCCESS,
        payload
    };
}

export function callDinamicDisable (payload) {
    return {
        type: CALL_DINAMIC_DISABLE,
        payload
    };
}

export function contactoAdd (payload) {
    return {
        type: CONTACTO_ADD,
        payload
    };
}

export function reloadPage (payload) {
    return {
        type: RELOAD_PAGE,
        payload
    };
}

export function selectDiciplina (payload) {
    return {
        type: SELECT_DICIPLINA,
        payload
    };
}

export function saveBase64 (payload) {
    return {
        type: SAVE_BASE64_PDF,
        payload
    };
}

export function initModalEspera (payload) {
    return {
        type: INIT_MODAL_ESPERA,
        payload
    };
}

export function disabledLote (payload) {
    return {
        type: CALL_DINAMIC_DISABLE_LOTE,
        payload
    };
}

