import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from 'react-bootstrap/Image';
import './sidebar.css';
import Nav from 'react-bootstrap/Nav';

export default class NavItem extends Component {

  handleClick = (path) => {
      const { onItemClick } = this.props;
      if (onItemClick) {
        return onItemClick(path);
      }

      return false;
  }

  render() {
    const { items } = this.props;
      return (
        items && items.map((item, i) => {
            const whiteClass = `styled-nav-item ${ item.className ? item.className : '' } ${ item.active ? 'active' : ''}`;
            const dropdown = item.dropdown;
            return true && (<Nav.Item as="li" key={item.key} className={whiteClass}>
            { 
              item.onItemClick ? 
              <Nav.Link href="#" className='sidebar-nav-link action' onClick={this.onLogout}>
                {
                  !item.position || item.position === 'left' ? 
                    <FontAwesomeIcon className='nav-icon' icon={item.css} />  
                  :
                  ''
                }
                <span className='link-text'>{item.label}</span>
                {
                  item.position === 'right' ? 
                    <FontAwesomeIcon className='nav-icon' icon={item.css} />  
                  :
                  ''
                }
              </Nav.Link>
              :
              <Link to={item.path} className='sidebar-nav-link '>
                {
                  !item.position || item.position === 'left' ?
                  item.picture ? <Image className='nav-image' src={item.picture} roundedCircle  /> :  
                  <FontAwesomeIcon className='nav-icon' icon={item.css} /> :
                  ''
                }
                <span className='link-text'>{item.label}</span>
                {
                  item.position && item.position === 'right' ? 
                  item.picture ? <Image className='nav-image' src={item.picture} roundedCircle  /> : 
                  <FontAwesomeIcon className='nav-icon' icon={item.css} /> :
                  ''
                }
              </Link>
            } 
            { dropdown ? 
              <div className="submenu">
                <Nav.Item as="ul">
                  <NavItem items={ dropdown } /> 
                </Nav.Item>
              </div>: ''}
          </Nav.Item>)
        })
      );

    return false;
  }
}
