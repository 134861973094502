import React, { Component } from 'react';
import CustomReduxForm from "../../components/materialform/customform";
import { BOTONES, getButtonLogin, LOGIN_FORM } from './constants';

export default class LoginComponent extends Component {
    componentDidMount() {
        const { loginAction } = this.props;

        // const loginButton = document.getElementById('js-btn-login');
        // loginButton.addEventListener("click", () => loginAction());
    }


    validate = (values) => {
        const errors = {}

        const requiredFields = [
            'email',
            'password',
        ]
        if (values.email ||  values.password){
            requiredFields.forEach(field => {
                if (!values[field]) {
                    errors[field] = 'Campo obligatorio'
                }
            })
    
            if (
                values.email &&
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
                errors.email = 'Correo electrónico invalido'
            }
        }
        

        return errors
    }

    render() {
        const { loginAction, loginFormValues } = this.props;

        return (
            <div className="contenedor-principal">
                <h2>INICIAR SESIÓN</h2>
                <p>Por favor indica la cuenta y contraseña para acceder al modulo de estadisticas</p>
                <div className="contenedor-interno">
                    <CustomReduxForm
                        card={true}
                        formName="adminLoginForm"
                        items={LOGIN_FORM}
                        validations={this.validate}
                        handleSubmit={() => loginAction(loginFormValues)}
                        buttons={getButtonLogin(loginFormValues)}
                        //formTitle="Iniciar Sesión"
                    />
                </div>
            </div>
        );
    }
}
