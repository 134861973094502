import React, { Component } from "react";
import "./navbar.css";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import SideBar from "../sidebar/sidebar";

class CustomNavBar extends Component {
  render() {
    const { items, menuItems, main, nomatch } = this.props;

    return (
      <Router>
            <Switch>
              {
                items.map((item,i) => {
                  return <Route key={i} path={item.path} component={item.component} />
                })
              }
              <Route exact path="/:company" component={main} />
              <Route component={nomatch} />
            </Switch>
      </Router>
    );
  }
}

export default CustomNavBar;