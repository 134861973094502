import React from 'react';
import ReactDOM from 'react-dom';
import './static/css/index.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { paths, main, menuItems } from './Constants';
import CustomNavBar from './components/navbar/navbar';



ReactDOM.render(
    <CustomNavBar items={paths} main={main} menuItems={menuItems} />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
