import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { storeInSession } from '../../controller/session';
import LinearProgress from '@material-ui/core/LinearProgress';
import './auth.css';
import { Redirect } from 'react-router-dom';

function AuthData({callbackPath, children}) {
  const {
    isAuthenticated,
    user,
    isLoading 
  } = useAuth0();

  if (isAuthenticated) {
    storeInSession('authUser', JSON.stringify(user));
  } else {
    sessionStorage.clear();
  }

  if (isLoading) {
    return (
        <div className="loading-wrapper">
            <LinearProgress />
            <div>
                Cargando información del usuario ...
            </div>
        </div>
    );
  }

  return (
    <div className="auth-wrapper"> 
      <Redirect to={callbackPath}></Redirect>
        {
          children
        }
    </div>
  );
}

export default AuthData;