import React, { Component } from 'react';
import SideNav from './sidenav';
import './sidebar.css';

export default class SideBar extends Component {
  render() {
    const {items, onLogout} = this.props;

    return (
      <SideNav items = {items} onLogout = {onLogout}></SideNav>
    );
  }
}
