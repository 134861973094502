import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import "./style.css";

import reducer from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchComponent } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';
import ComponentName from './component';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    localReducer: reducer,
    form: formReducer,
});

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchComponent);

export default class IndexPage extends Component {
    render() {
        return (
            <Provider store={store}>
                <Container className='main-wrapper pdf-component' >
                    <ComponentName urlparams={this.props.location.pathname} />
                </Container>
            </Provider>
        );
    }
}
