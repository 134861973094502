export const GET_CONVERSATIONS = '@conversations/getAll';
export const GET_CONVERSATIONS_SUCCESS = '@conversations/getUsersSuccess';
export const GET_CONVERSATIONS_ERROR = '@conversations/getUsersError';

export const CONV_HEADERS = [

    {label: "email", column: "email"},
    {label: "Usuario", column: "usuario"},
];

export const USER_DATA = [

];
