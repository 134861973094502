import { createStructuredSelector } from 'reselect';

export const getUsers = (state) => state.users;
export const getTotalUsers = (state) => state.totalUsers;
export const getItemsPerPage = (state) => state.itemsPerPage; 
export const getPagination = (state) => state.pagination;
export const getPage = (state) => state.page;

export const usersSelector = createStructuredSelector({
    users: getUsers,
    totalUsers: getTotalUsers
});