import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getUsers, getTotalUsers, getItemsPerPage, getPagination, getPage } from "./selectors";
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    FILTER_USERS,
    GET_USERS_REAL_TIME,
    PAGE_SIZE,
    PAGINATE_USERS,
    SELECT_ROWS_PER_PAGE
} from './constants';


class ComponentHome extends Component {

    componentDidMount () {
        const { getUsersRealTime } = this.props;
        getUsersRealTime();
    }

    componentDidUpdate (prevProps) {
        const { users, paginatePage, itemsPerPage, pagination, page } = this.props;
        const isChanged = users !== prevProps.users;

        if (isChanged && pagination) {
            paginatePage({
                users, 
                page,
                itemsPerPage
            });
        }
    }

    handlerPagination = (event, newPage) => {
        const { totalUsers, itemsPerPage, paginatePage } = this.props;

        paginatePage({
            users: totalUsers, 
            page: newPage,
            itemsPerPage
        });
    }

    handlerPaginationPage = (event) => {
        const { totalUsers, paginatePage } = this.props;

        paginatePage({
            users: totalUsers, 
            page: 0,
            itemsPerPage: parseInt(event.target.value, 10)
        });
    }

    render() {
        const {
            userHeaders, 
            users,
            totalUsers,
            filterUsers,
            itemsPerPage,
            page
        } = this.props;

        return (
            <div className="App home-container">
                <div className='user-list_wrapper'>
                { users && !users.empty ? 
                    <CustomTable 
                        className="users-table" 
                        items={users} 
                        totalItems={totalUsers}
                        headers={userHeaders}
                        paginator={true}
                        onSelectRow={false}
                        filterTable = {filterUsers}
                        pageSize = {PAGE_SIZE}
                        handlerPagination = {this.handlerPagination}
                        rowsPerPage = {itemsPerPage}
                        page = {page}
                        onChangeRowsPerPage={this.handlerPaginationPage}
                    /> :
                    <CircularProgress color="secondary" />
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        userHeaders: state.userHeaders,
        totalUsers: getTotalUsers(state),
        users: getUsers(state),
        itemsPerPage: getItemsPerPage(state),
        pagination: getPagination(state),
        page : getPage(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        getUsersRealTime: () => dispatch({ type: GET_USERS_REAL_TIME, value: 1 }),
        filterUsers: (value) => dispatch({ type: FILTER_USERS, value }),
        paginatePage: (value) => dispatch({ type: PAGINATE_USERS, value }),
        selectRowsPerPage: (value) => dispatch({ type: SELECT_ROWS_PER_PAGE, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentHome);