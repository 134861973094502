export const GET_USERS_SUCCESS = '@users/getUsersSuccess';
export const GET_USERS_ERROR = '@users/getUsersError';
export const GET_USERS_REAL_TIME = '@users/getUsersrRealTime';
export const FILTER_USERS = '@users/filterUsers';
export const FILTER_USERS_SUCCESS = '@users/filterUsersSuccess';
export const PAGINATE_USERS = '@users/paginateUser';
export const PAGINATE_USERS_SUCCESS = '@users/paginateUserSuccess';
export const SELECT_ROWS_PER_PAGE = '@users/selectRowsPageSuccess';

export const ITEMS_PER_PAGE = 5;

export const USER_HEADERS = [
    {label: "id", column: "id"}, 
    {label: "email", column: "email"},
    {label: "first name", column: "name"},
    {label: "last name", column: "last_name"},
    {label: " ", column: " "},
];

export const PAGE_SIZE = [5, 10, 15];

export const USER_DATA = [

];
