import { ToysTwoTone } from '@material-ui/icons';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import React, { Component } from 'react';
import { emptyObject} from "../../controller/utils";

export default class MyPdfDeportes extends Component {
    constructor(props) {
        super(props);
    }

    styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#fff'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        backgroundImg: {
            position: 'absolute',
            left: 0,
            top: 0
        },
        centerImage: {
            alignItems: "center",
            flexGrow: 1
        },
        image: {
            padding: 0,
            width: '100%',
            position: 'absolute',
            left: 0
        },
        imagePosition: (left, right, top, size,size2) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                width: size ? size : '100%',
                height: size2 ? size2 : '100%',
                position: 'absolute'
            };
        },
        fontText: (left, right, top, size) => {
            return {
                left: left ? `${left}` : 'auto',
                right: right ? `${right}` : 'auto',
                top: top ? `${top}` : 'auto', // 175
                fontSize: size ? size : 9,
                position: 'absolute',
                fontWeight: 'bold'
            };
        },
        
    });
    fontSize(cadena){
        return cadena.length>70 ? 5 
        : cadena.length>60 ? 6 : cadena.length>50 ? 7 : 8;         
    };
    fontSizeM(cadena){
        return cadena.length>60? 4 : cadena.length>50? 5 
        : cadena.length>40 ? 6 : cadena.length>30 ? 7 : 8;         
    };

    fontSizeA(cadena){
        return cadena.length>55? 2 : cadena.length>41? 3 : cadena.length>32? 4 : cadena.length>25? 5
        : cadena.length>21 ? 6 : cadena.length>19 ? 7 : 8;         
    };

    render() {
        const { data, id } = this.props;
        console.log(data);
        var nuevo=""
        const company = data.datos.companydataform;
        const generales = data.datos.datosgenerales;
        const responsable = data.datos.datosresponsable;
        const personal = data.datos.informacionpersonal;
        const splitDate = personal?.birthdate?.split('-');
        
        return (
            <React.Fragment>
                <Document>
                    <Page size="A4" style={this.styles.page}>
                        <View style={this.styles.section}>
                            <Image style={this.styles.image} src="/pdfRecreacion.jpg" />
                            {/* COMPANY DATA FORM*/}
                            <Text style={this.styles.fontText(170, false, 140, this.fontSize(company.oportunidad))}>{company.oportunidad}</Text>
                            <Text style={this.styles.fontText(268, false, 140, this.fontSize(company.centroRecreacional))}>{company.centroRecreacional}</Text>
                            <Text style={this.styles.fontText(140, false, 163, this.fontSize(company.grupo ? company.grupo :""))}>{company.grupo ? company.grupo :""}</Text>
                            <Text style={this.styles.fontText(268, false, 163, this.fontSize(company.periodoInscripcion ? company.periodoInscripcion : " "))}>{company.periodoInscripcion ? company.periodoInscripcion : " "}</Text>

                            
                            { /* INFORMACIÓN PARTICIPANTE */}
                            <Text style={this.styles.fontText(138, false, 214, this.fontSize(personal.primerNombre))}>{personal.primerNombre}</Text>
                            <Text style={this.styles.fontText(270, false, 214, this.fontSize(personal.segundoNombre))}>{personal.segundoNombre}</Text>
                            <Text style={this.styles.fontText(375, false, 214, this.fontSize(personal.apellidos))}>{personal.apellidos}</Text>

                            <Text style={this.styles.fontText(160, false, 232, this.fontSize(personal.tipoDocumentoMenor))}>{personal.tipoDocumentoMenor}</Text>
                            <Text style={this.styles.fontText(345, false, 232, this.fontSize(personal.idDocumento))}>{personal.idDocumento}</Text>

                            {/* <Text style={this.styles.fontText(185, false, 249, this.fontSize(personal.depaExp))}>{personal.depaExp}</Text>
                            <Text style={this.styles.fontText(395, false, 249, this.fontSize(personal.cityExp))}>{personal.cityExp}</Text> */}

                            <Text style={this.styles.fontText(personal.sexo === "1" ? 143 : 115, false, 266, 7)}>X</Text>
                            <Text style={this.styles.fontText(272, false, 266, this.fontSize(splitDate[2]))}>{splitDate[2]}</Text>
                            <Text style={this.styles.fontText(302, false, 266, this.fontSize(splitDate[1]))}>{splitDate[1]}</Text>
                            <Text style={this.styles.fontText(333, false, 266, this.fontSize(splitDate[0]))}>{splitDate[0]}</Text>
                            <Text style={this.styles.fontText(440, false, 266, this.fontSize(personal.age))}>{personal.age}</Text>
                            
                            <Text style={this.styles.fontText(120, false, 283, this.fontSize(personal.direccionHome))}>{personal.direccionHome}</Text>
                            <Text style={this.styles.fontText(395, false, 283, this.fontSize(personal.depaPlace))}>{personal.depaPlace}</Text>

                            <Text style={this.styles.fontText(110, false, 300, this.fontSize(personal.cityPlace))}>{personal.cityPlace}</Text>   
                            {/* <Text style={this.styles.fontText(262, false, 300, this.fontSize(personal.hoodDepor))}>{personal.hoodDepor}</Text>    */}
                            
                            <Text style={this.styles.fontText(110, false, 318, this.fontSize(generales.entidadEps))}>{generales.entidadEps}</Text>   
                            
                            <Text style={this.styles.fontText(generales.medicinaPrepagada === "NO" ? 212:175, false, 335, this.fontSize("X"))}>X</Text>   
                            {/* <Text style={this.styles.fontText(274, false, 335, this.fontSize(generales.entidadMep))}>{generales.entidadMep}</Text>    */}
                            
                            <Text style={this.styles.fontText(160, false, 352, this.fontSize(generales.grupoSanguineo))}>{generales.grupoSanguineo}</Text>   
                            
                            <Text style={this.styles.fontText(generales.enfermedad === "NO" ? 390:333, false, 369, this.fontSize("X"))}>X</Text>   
                            
                            <Text style={this.styles.fontText(110, false, 386, this.fontSize(generales.answerEnfermedad))}>{generales.answerEnfermedad}</Text>   
                            
                            <Text style={this.styles.fontText(generales.medicamento === "NO" ? 365:313, false, 404, this.fontSize("X"))}>X</Text>   
                            
                            <Text style={this.styles.fontText(110, false, 421, this.fontSize(generales.answerMedicamento))}>{generales.answerMedicamento}</Text> 
                            <Text style={this.styles.fontText(305, false, 421, this.fontSize(generales.dosis))}>{generales.dosis}</Text>   

                            {/* DATOS DEL RESPONSABLE */}
                            <Text style={this.styles.fontText(155, false, 485, this.fontSize(responsable.nombresRes))}>{responsable.nombresRes}</Text>
                            <Text style={this.styles.fontText(150, false, 502, this.fontSize(responsable.emailRes))}>{responsable.emailRes}</Text>
                            <Text style={this.styles.fontText(120, false, 519, this.fontSize(responsable.cellPhoneRes))}>{responsable.cellPhoneRes}</Text>
                            <Text style={this.styles.fontText(300, false, 519, this.fontSize(responsable.phoneRes))}>{responsable.phoneRes}</Text>   
                        </View>
                    </Page>
                </Document>
            </React.Fragment>
        );
    }
}
