import React, { Component } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import {Image } from "react-bootstrap";


export default class CustomButton extends Component {
    render() {
        const { items } = this.props;
        return (
            <div className="button-wrapper">
                <ButtonGroup color="primary" aria-label="primary button group">
                    {
                        items && items.map((button, k) => {
                            console.log(button,"aqui")
                            return <Button
                                key={`button-${button.label}-${k}`}
                                variant={button.variant}
                                color={button.style}
                                type="submit"
                                className={button.className}
                                onClick={button.onClick}
                                pressed={true}
                            >
                                {button.image ?
                                    <div>
                                        <Image className='card-image' src={button.imageSrc} />
                                        <span class="btn-text">{button.label}</span>
                                    </div>
                                    : button.label}
                            </Button>
                        })
                    }
                </ButtonGroup>
            </div>
        );
    }
}
