import React, { Component } from 'react';
import { getComponentVariable, getComponentData } from "./selectors";
import { INIT_ACTION } from './constants';
import { connect } from "react-redux";
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from './mydocument';
import { LinearProgress } from '@material-ui/core';
import MyPdfDeportes from './pdfDeportes';

class ComponentName extends Component {
    convertUrlParams(urlparams) {
        const parameter = urlparams.split("/");
        const values = {
            formid: parameter[3],
            formname: parameter[2]
        }
        return values;
    }

    render() {
        const { urlparams, newFuction, componentData, componentVariable } = this.props;
        const values = this.convertUrlParams(urlparams);
        if (!componentVariable) {
            newFuction(values);
        }
        console.log(componentData);

        return (
            <React.Fragment>
                <div className="main-document">
                    {componentData && componentData.formname ==="vivienda" ?
                        <PDFViewer className="pdf-doc">
                            <MyDocument data={componentData} />
                        </PDFViewer>
                        : componentData && componentData.formname ==="deportes" ?
                        <PDFViewer className="pdf-doc">
                            <MyPdfDeportes  data={componentData} />
                        </PDFViewer>
                        :<LinearProgress />}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        componentVariable: getComponentVariable(state),
        componentData: getComponentData(state)
    };
};

const mapDispachToProps = dispatch => {
    return {
        newFuction: (value) => dispatch({ type: INIT_ACTION, value })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentName);