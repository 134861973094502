import React, { Component } from 'react';
import Container from 'react-bootstrap/Container'

import "./style.css";
import ComponentLargeForm from './component';

import { reducer } from "./reducer";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reducer as formReducer } from 'redux-form';

import { Auth0Provider } from "@auth0/auth0-react";
import "@fontsource/red-hat-display";

import { Route, Routes } from 'react-router-dom';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    localReducer: reducer,
    form: formReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(watchUsers);


export default class ViviendaPage extends Component {
    
    render() {
        return (
            // <Auth0Provider
            //     // domain={process.env.REACT_APP_AUTH0_DOMAIN}
            //     // clientId={process.env.REACT_APP_AUTH0_CLIENTID}
            //     // redirectUri={`${window.location.origin}/vivienda`}
            // >
                <Provider store={store}>
                    <Container className='main-wrapper auth' >
                        <ComponentLargeForm params={this.props.match.params} />
                    </Container>
                </Provider>
        );
    }
}
