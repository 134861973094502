export const UPDATE_FIELD_ATTRIBUTE = '@deporte/updateAttribute';
export const LOAD_CATALOG = '@deporte/loadCatalog';
export const LOAD_CATALOG_SUCCESS = '@deporte/loadCatalogSuccess';
export const CARGAR_CATALOGOS = '@deporte/cargarCatalogos';
export const LOAD_DOCUMENT_SUCCESS = '@deporte/loadDocumentSuccess';
export const CREATE_DEPOR_POSTULATION = '@deporte/createDeporPostulation';
export const REDUX_CHANGE = '@@redux-form/CHANGE';
export const CALL_DINAMIC_DISABLE = '@deporte/callDinamicDisable';
export const CLOSE_MODAL = '@deporte/closeModal';
export const CONTACTO_ADD = '@deporte/contactoAdd';
export const RELOAD_PAGE = '@deporte/reloadPage';
export const SELECT_DICIPLINA = '@deporte/selectDiciplina';
export const SELECT_OPORTUNIDAD_URL = '@deporte/selectOportunidadUrl';
export const SAVE_BASE64_PDF = '@deporte/saveBase64';
export const INICIALIZE_FORM_FROM_OPORTUNIDAD = '@deporte/initalizeFormFromOportunidad';
export const INIT_MODAL_ESPERA = '@deporte/initModalEspera';
export const CONTACTO_ADD_COMPONENT = '@deporte/contactAddComponent';
export const CALL_DINAMIC_DISABLE_LOTE = '@deporte/disabledLote'

export const JURAMENTO_DEPORTES = `
Con mi firma que aparece dentr del presente documento, de manera previa,expresa
e inequívoca autorizo a COMFENALCO VALLE DELAGENTE el tratamiento de mis datos personales
(o el tratamiento de los datos personales del menor de edad o persona en condición de discapasidad mental que represento),
para que sean almacenados, usados y puestos en circulación o suprimidos, conforme a la Politica de Tratamiento de la Información
que la corporación a optado y que se encuentra publicada en la página web www.comfenalcovalle.com.co, la cual declaro conocer y por ello
estar informado de las finalidades de dicho tratamiento al estar en ella consignadas. También declaro que he sido informado para que,
para el ejercicio de mis derechos(acceder, conocer, actualizar, rectificar, suprimir mis datos personales, etc.), podré dirigirme ante la 
Corporación ÚNICAMENTE a través del correo electrónico servicioalcliente@confenalcovalle.com.co, líneas telefónicas 8862727 - 018000938585, 
diligenciando los formatos de los buzones de sugerencias y de manera presencial en las sedes de la corporación o enviando comunicación escrita a la dirección calle 5 No 6-63 en la ciudad de Santiago de Cali 
(Valle del cauca).
Así mismo, declaro que se me ha advertido la posibilidad de oponerme la tratamiento de datos sensibles como mi imagen, datos de salud, datos biométricos,datos del menor de edad, origen étnico, género, condición de discapacidad, entre otros a lo cual manifiesto mi autorización expresa para su tratamiento.`;

export const REQUIRED_INFORMACION_PERSONAL = [
    "primerNombre",
    "apellidos",
    "tipoDocumentoMenor",
    "idDocumento",
    "sexo",
    "birthdate",
    "age",
    "direccionHome",
    "departamentDepor",
    "cityDepor",
];

export const REQUIRED_DATOS_EPS = [
    "entidadEps",
    "grupoSanguineo",
    // "medicinaPrepagada",
    "enfermedad",
    "medicamento",

];

export const REQUIRED_COMPANY_DATAFORM = [
    "oportunidad",
    "centroRecreacional",
    // "grupo",
    // "periodoInscripcion"
];

export const REQUIRED_DATOS_RESPONSABLE = [
    "tipoDocumentoRes",
    "idDocumentoRes",
    "nombresRes",
    "cellPhoneRes",
    "emailRes",
];

export const REQUIRED_PADRES_EPS = [
    { padre: "medicamento", hijo: "answerMedicamento" },
    { padre: "medicamento", hijo: "dosis" },
    { padre: "enfermedad", hijo: "answerEnfermedad" },
    // { padre: "medicinaPrepagada", hijo: "entidadMep" },
];

export const IMAGES_DICIPLINA = [
    { src: "/imagesDiciplinas/depor-futbol.png", name: "FÚTBOL" },
    { src: "/imagesDiciplinas/depor-natacion.png", name: "NATACIÓN" },
    { src: "/imagesDiciplinas/depor-taekwondo.png", name: "TAEKWONDO" },
    { src: "/imagesDiciplinas/depor-tenis.png", name: "TENIS" },
];

export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "content-type": "application/json;charset=iso-8859-1",
    "Cache-Control": "no-cache",
    'mode': "opaque ",
    auth: {
        username: "FORMULARIOSWEB",
        password: 'Yk2190_w'
    }
}

export const EMAIL_RESPUESTA = [
    { tipo: "Buga", email: "phguerrero@comfenalcovalle.com.co" },
    { tipo: "Cali", email: "dfgranada@comfenalcovalle.com.co" },
    { tipo: "Palmira", email: "lvargas@comfenalcovalle.com.co" },
    { tipo: "Tuluá", email: "phguerrero@comfenalcovalle.com.co" },
    { tipo: "Cartago", email: "jmrivera@comfenalcovalle.com.co" },
    { tipo: "Buenaventura", email: "mamontano@comfenalcovalle.com.co,leguzman@comfenalcovalle.com.co" },
    { tipo: "vacaciones", email: "favalencia@comfenalcovalle.com.co,dpcano@comfenalcovalle.com.co" }
]

export const COMPANY_PEOPLE_FORM = [
    {
        tooltip: "Ingrese el número de oportunidad si no aparece en el formulario",
        label: "Número de oportunidad*",
        name: "oportunidad",
        placeholder: "Ingrese el número de oportunidad",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        disabled: true,
        className: "rs-30"
    },
    {
        tooltip: "Seleccione el municipio en el cual inscribir la academia deportiva",
        label: "Municipio*",
        name: "centroRecreacional",
        placeholder: "Seleccione el municipio",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [

        ],
        disabled: true,
        className: "rs-30"
    },
   
]

export const COMPANY_FORM = [
    {
        label: "Primer nombre*",
        name: "primerNombre",
        placeholder: "Primer nombre*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: true,
        className: "form-control rs-30"
    },
    {
        label: "Segundo nombre",
        name: "segundoNombre",
        placeholder: "Segundo nombre",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: true,
        className: "form-control rs-30"
    },
    {
        label: "Apellidos*",
        name: "apellidos",
        placeholder: "Apellidos*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: true,
        className: "form-control rs-30"
    },
    {
        label: "Tipo de identificación*",
        name: "tipoDocumentoMenor",
        placeholder: "Tipo de identificación*",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    {
        label: "Número de identificación*",
        name: "idDocumento",
        placeholder: "Número de identificación*",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    // {
    //     label: "Departamento expedición*",
    //     name: "deptoExpedida",
    //     placeholder: "Expedida en*",
    //     hint: "",
    //     type: "select",
    //     typeForm: "select",
    //     value: false,
    //     options: [],
    //     disabled: false,
    //     className: "rs-30"
    // },
    // {
    //     label: "Municipio de expedición*",
    //     name: "cityExpedida",
    //     placeholder: "Expedida en*",
    //     hint: "",
    //     type: "select",
    //     typeForm: "select",
    //     value: false,
    //     options: [],
    //     disabled: false,
    //     className: "rs-30"
    // },
    {
        label: "Sexo*",
        name: "sexo",
        placeholder: "Sexo*",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    {
        label: "Fecha de nacimiento*",
        name: "birthdate",
        placeholder: "",
        hint: "",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30 dateFecha"
    },
    {
        label: "Edad*",
        name: "age",
        placeholder: "Edad",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    {
        label: "Dirección / domicilio actual*",
        name: "direccionHome",
        placeholder: "Dirección / domicilio actual",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    {
        label: "Departamento*",
        name: "departamentDepor",
        placeholder: "Departamento",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    {
        label: "Ciudad*",
        name: "cityDepor",
        placeholder: "Ciudad",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
    // {
    //     label: "Barrio*",
    //     name: "hoodDepor",
    //     placeholder: "Barrio",
    //     hint: "",
    //     type: "text",
    //     typeForm: "text",
    //     value: false,
    //     options: [],
    //     disabled: false,
    //     className: "rs-30"
    // },
]

export const DATOS_GEN_FORM = [
    
    {
        tooltip: "Ingrese el nombre de su EPS",
        label: "Entidad EPS*",
        name: "entidadEps",
        placeholder: "Entidad EPS*",
        type: "text",
        typeForm: "text",
        options: [],
        value: false,
        disabled: false,
        className: "form-control rs-70"
    },
    {
        tooltip: "Ingrese su grupo sanguineo por ejemplo O+",
        label: "Grupo Sanguineo*",
        name: "grupoSanguineo",
        placeholder: "Grupo Sanguineo*",
        type: "text",
        typeForm: "text",
        options: [],
        value: false,
        disabled: false,
        className: "form-control rs-30"
    },
    // {
    //     tooltip: "Seleccione si, si cuenta con medicina prepagada",
    //     label: "Medicina Prepagada*",
    //     name: "medicinaPrepagada",
    //     placeholder: "Medicina Prepagada*",
    //     type: "radio2",
    //     typeForm: "radio2",
    //     value: false,
    //     options: [
    //         { label: 'Si', value: '1', className: "radioSi", },
    //         { label: 'No', value: '2', className: "radioNo", },
    //     ],
    //     disabled: false,
    //     className: "form-control rs-40"
    // },
    // {
    //     tooltip: "Ingrese el nombre de la entidad de medicina prepagada",
    //     label: "Entidad MEP*",
    //     name: "entidadMep",
    //     placeholder: "Entidad MEP*",
    //     type: "text",
    //     typeForm: "text",
    //     options: [],
    //     value: false,
    //     disabled: false,
    //     className: "form-control rs-60"
    // },
    {
        tooltip: "Seleccione si, si el menor inscrito presenta alguna enfermedad",
        label: "¿El menor presenta alguna enfermedad y/o discapacidad?*",
        name: "enfermedad",
        placeholder: "¿El menor presenta alguna enfermedad?*",
        type: "radio2",
        typeForm: "radio2",
        value: false,
        options: [
            { label: 'Si', value: '1', className: "radioSi" },
            { label: 'No', value: '2', className: "radioNo" },
        ],
        disabled: false,
        className: "form-control rs-40"
    },
    {
        tooltip: "Ingrese el nombre de la enfermedad que presenta el menor",
        label: "¿Cual?",
        name: "answerEnfermedad",
        placeholder: "¿Cual?*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-60"
    },

    {
        tooltip: "Seleccione si, si el menor toma algun tipo de medicamento",
        label: "¿Toma algún medicamento?*",
        name: "medicamento",
        placeholder: "¿Toma algún medicamento?*",
        type: "radio2",
        typeForm: "radio2",
        value: false,
        options: [
            { label: 'Si', value: '1', className: "radioSi" },
            { label: 'No', value: '2', className: "radioNo" },
        ],
        disabled: false,
        className: "form-control rs-40"
    },
    {
        tooltip: "Ingrese el nombre del medicamento",
        label: "¿Cual?",
        name: "answerMedicamento",
        placeholder: "¿Cual?*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-40"
    },
    {
        tooltip: "Ingrese la dosis ordenada del medicamento del menor",
        label: "Dosis",
        name: "dosis",
        placeholder: "Dosis",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-20"
    },
]

export const DATOS_RESP_FORM = [
    {
        label: "Tipo de identificación*",
        name: "tipoDocumentoRes",
        placeholder: "Tipo de identificación*",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: false,
        className: "rs-25"
    },
    {
        label: "Número de identificación*",
        name: "idDocumentoRes",
        placeholder: "Número de identificación*",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disabled: false,
        className: "rs-25"
    },
    {
        label: "Nombres completos*",
        name: "nombresRes",
        placeholder: "Nombres completos del acudiente*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-50"
    },
    {
        label: "Correo electrónico*",
        name: "emailRes",
        placeholder: "Correo electrónico*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    },
    {
        label: "Celular*",
        name: "cellPhoneRes",
        placeholder: "Celular*",
        type: "number",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    },
    {
        label: "Teléfono",
        name: "phoneRes",
        placeholder: "Teléfono",
        type: "number",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    }
]

export const DATOS_ACUDIENTE_FORM = [
    {
        label: "Nombres completos*",
        name: "nombresAcu",
        placeholder: "Nombres completos del acudiente*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-50"
    },
    {
        label: "Correo electrónico*",
        name: "emailAcu",
        placeholder: "Correo electrónico*",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    },
    {
        label: "Celular*",
        name: "cellPhoneAcu",
        placeholder: "Celular*",
        type: "number",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    },
    {
        label: "Teléfono",
        name: "phoneAcu",
        placeholder: "Teléfono",
        type: "number",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "form-control rs-30"
    }
]

const JURAMENTO_PARTES = [
    {
        label: "",
        name: "habeasData",
        type: "link",
        typeForm: "link",
        disabledd: false,
        className: "rs-100-2 estiloTrata"
    },
    {
        placeholder: "He leído y acepto las condiciones estipuladas en el ",
        label: "",
        name: "tratamientoCheck",
        type: "link2",
        typeForm: "link2",
        options: ["reglamento"],
        disabledd: false,
        className: "rs-100-2 estiloTrata"
    },


]

export const COMPANY_CONTACT_FORM = [
    {
        label: "",
        name: "companydataform",
        type: "section",
        typeForm: "section",
        className: "rs-100",
        fields: COMPANY_PEOPLE_FORM

    },
    {
        label: "Información participante",
        name: "informacionpersonal",
        type: "section",
        typeForm: "section",
        className: "rs-100 class_test",
        fields: COMPANY_FORM
    },
    {
        label: "",
        name: "datosgenerales",
        type: "section",
        typeForm: "section",
        className: "rs-100 class_test",
        fields: DATOS_GEN_FORM
    },
    // {
    //     label: "Datos del acudiente",
    //     name: "datosacudiente",
    //     type: "section",
    //     typeForm: "section",
    //     className: "rs-100 class_test",
    //     fields: DATOS_ACUDIENTE_FORM
    // },
    {
        label: "Datos de la persona que deja y recoge al menor",
        name: "datosresponsable",
        type: "section",
        typeForm: "section",
        className: "rs-100 class_test",
        fields: DATOS_RESP_FORM
    },
    {
        label: "TRATAMIENTO DE DATOS PERSONALES",
        name: "juramentoPartes",
        type: "section",
        typeForm: "section",
        className: "rs-100 juramentoPartes firmaTratamiento",
        fields: JURAMENTO_PARTES
    }
]

export const MAIN_BUTTONS = [
    {
        label: "Enviar",
        className: "auth-btn-send",
        action: false,
        style: "primary",
        primary: true,
        onClick: false
    },
    // {
    //     label: "Cancelar",
    //     className: "auth-btn-send",
    //     action: false,
    //     style: "secondary",
    //     primary: true,
    //     onClick: false
    // }
]

export const getLoginButton = (handlerSafe) => {
    return [
        {
            label: "Enviar",
            className: "auth-btn-send",
            action: false,
            style: "primary",
            primary: true,
            onClick: handlerSafe
        }
    ]
}
