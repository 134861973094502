import { firebaseAuthentication } from "./firebase";

export const storeInSession = (item, value) => {
    sessionStorage.setItem(item, value);
}

export const saveInSession = (item, value) => {
    sessionStorage.setItem(item, JSON.stringify(value));
}

export const getFromSession = (item) => {
    return JSON.parse(sessionStorage.getItem(item));
}

export const logout = () => {
    
    sessionStorage.clear();
    //firebaseAuthentication.signOut();
}


