import { takeLatest, put, call } from "redux-saga/effects";

import { 
  GET_CONVERSATIONS,
} from './constants';

import { 
  getConversationsSuccess,
  getConversationsError,
} from './actions';

import { firebaseDatabase } from "../../controller/firebase";
import { change } from "redux-form";

function* getConversations() {
  const fireResponse = yield firebaseDatabase.collection('converations').get();

  const responseData = {
    data: []
  };

  if(fireResponse) {
    fireResponse.forEach(element => {
      const { email, usuario } = element.data();
      responseData.data.push({ email, usuario });
    });
  }

  const {data} = responseData;

  if (data.length > 0) {
    yield put(getConversationsSuccess(responseData));
    return;
  }
}

export function* watchConversation() {
  yield takeLatest(GET_CONVERSATIONS, getConversations);
}
