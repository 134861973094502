import {
  GET_USERS_SUCCESS,
  USER_HEADERS,
  FILTER_USERS_SUCCESS,
  PAGINATE_USERS_SUCCESS,
  ITEMS_PER_PAGE
} from './constants';
  
  export const initialState = {
    users: false,
    userHeaders: USER_HEADERS,
    totalUsers: false,
    pagination: false,
    itemsPerPage: ITEMS_PER_PAGE,
    page: 0
  };
  
  const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type } = action;
    const { data, page, itemsPerPage } = payload ? payload : {};

    switch (type) {
      case GET_USERS_SUCCESS:
          newState.users = data;
          newState.totalUsers = data;
          newState.pagination = true;

          return {
            ...newState
          };
      case FILTER_USERS_SUCCESS:
            newState.users = data;
            newState.pagination = false;
  
            return {
              ...newState
            };
      case PAGINATE_USERS_SUCCESS:
            newState.users = data;
            newState.pagination = false;
            newState.page = page;
            newState.itemsPerPage = itemsPerPage;
  
            return {
              ...newState
            };
      default:
        return {
          ...newState
        };
    }
  };
  
  export default reducer;
  