import React, { Component, useState } from "react";
import "./style.css";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import CustomForm from "../../components/customform/customform";
import CustomReduxForm from "../../components/materialform/customform";
import Modal from "@material-ui/core/Modal";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Toast } from "primereact/toast";
import { Alert, Card } from "react-bootstrap";

import {
  getAuthForm,
  getSelectedForm,
  getSelectedTab,
  getMessage,
  getAuthError,
  getLogSuccess,
  getSelectedType,
  getSelectedCompanyForm,
  getFormInitiated,
  getPeopleFormValues,
  getCompanyFormValues,
  getFormFiles,
  getDynamicOptions,
  getDynamicDisable,
  getCharged,
  getReloadPage,
  getContactoAdd,
  getHiddenDisable,
  getConsultaValidador,
  getChangeType,
  getValidar,
} from "./selectors";

import {
  TOOGLE_FORM,
  UPDATE_FIELD_ATTRIBUTE,
  UPDATE_COMPANY_FIELD_ATTRIBUTE,
  SELECT_FORM_FIELD,
  getLoginButton,
  FORM_HEADER,
  FORM_PARAGRAPH,
  PEOPLE_FORM_TITLE,
  COMPANY_FORM_TITLE,
  COMPANY_CONTACT_FORM,
  SELECT_TAB,
  TAB_LOG_IN,
  INIT_USER_DATA,
  SIGN_USER,
  PEOPLE_KEY,
  COMPANY_KEY,
  peopleContactForm,
  peopleContactForm2,
  CREATE_COMFE_TICKET,
  UPLOAD_FILE,
  getCompanyContactForm,
  REMOVE_FILE,
  LOAD_CATALOG,
  REDUX_CHANGE,
  CLEAR_FILE,
  CLOSE_MODAL,
  VALIDATE_FORM,
  SELECT_FOR_URL,
  REQUIRED_SOLICITUD,
  REQUIRED_PERSONAL_COMPANY,
  REQUIRED_COMPANY,
  REQUIRED_PERSONAL,
  getTabButton,
} from "./constants";

import { Redirect } from "react-router-dom";
import AuthData from "../../components/authlogin/authdata";
import { getFromSession } from "../../controller/session";
import Button from "@material-ui/core/Button";

class ComponentAuth extends Component {
  componentDidMount() {
    const { loadCatalog, selectForUrl } = this.props;
    loadCatalog();

    const valores = window.location.search;

    if (valores.length > 0) {
      const urlParams = new URLSearchParams(valores);
      var type = urlParams.get('type');
      if (type.length > 0) {
        selectForUrl({ tipo: type });
      }
    } else {
      selectForUrl({ tipo: "0" });
    }
  }

  componentDidUpdate(prevProps) {
    const { contactoAdd } = this.props;
    if (contactoAdd !== prevProps.contactoAdd) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

  }

  llamarCities(value) {
    const { loadCatalogCitis } = this.props;
    loadCatalogCitis(value);
  }

  validate = (values) => {
    const { validateForm, validar } = this.props;
    let errors = {
      companydataform: {},
      informacionpersonal: {},
      informacionpersonalcompany: {},
      companyrequestform: {},
      informacionsolicitud: {},
    };

    if (values && !validar) {

      const requiredPersonal = [
        "abreviatura",
        "identificacion",

      ];

      if (values.informacionpersonal) {
        requiredPersonal.forEach((field) => {
          if (!values.informacionpersonal[field]) {
            errors.informacionpersonal[field] = "Campo obligatorio";
          }
        });
      }


    }

    if (values && validar) {
      const requiredCompany = REQUIRED_COMPANY;
      const requiredPersonal = REQUIRED_PERSONAL;
      const requiredPersonalcompany = REQUIRED_PERSONAL_COMPANY;
      const requiredRequest = REQUIRED_SOLICITUD;
      const requiredSolicitud = REQUIRED_SOLICITUD;
      if (
        values.companyrequestform &&
        values.companyrequestform.requestText
        && values.companyrequestform.requestText.length >= 2001
      ) {
        errors.companyrequestform.requestText = "Longitud maxima 200 caracteres";
      }
      if (
        values.informacionsolicitud &&
        values.informacionsolicitud.requestText
        && values.informacionsolicitud.requestText.length >= 2001
      ) {
        errors.informacionsolicitud.requestText = "Longitud maxima 200 caracteres";
      }


      if (values.companydataform) {
        requiredCompany.forEach((field) => {
          if (!values.companydataform[field]) {
            errors.companydataform[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionpersonal) {
        requiredPersonal.forEach((field) => {
          if (!values.informacionpersonal[field]) {
            errors.informacionpersonal[field] = "Campo obligatorio";
          }
        });
      }
      if (values.informacionpersonalcompany) {
        requiredPersonalcompany.forEach((field) => {
          if (!values.informacionpersonalcompany[field]) {
            errors.informacionpersonalcompany[field] = "Campo obligatorio";
          }
        });
      }

      if (values.companyrequestform) {
        requiredRequest.forEach((field) => {
          if (!values.companyrequestform[field]) {
            errors.companyrequestform[field] = "Campo obligatorio";
          }
        });
      }

      if (values.informacionsolicitud) {
        requiredSolicitud.forEach((field) => {
          if (!values.informacionsolicitud[field]) {
            errors.informacionsolicitud[field] = "Campo obligatorio";
          }
        });
      }

      if (
        values.informacionpersonal &&
        values.informacionpersonal.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z.]{2,4}$/i.test(
          values.informacionpersonal.email
        )
      ) {
        errors.informacionpersonal.email = "Correo electrónico invalido";
      }

      if (
        values.informacionpersonalcompany &&
        values.informacionpersonalcompany.email &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.informacionpersonalcompany.email
        )
      ) {
        errors.informacionpersonal.email = "Correo electrónico invalido";
      }

      if (
        values.companydataform &&
        values.companydataform.companyEmail &&
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          values.companydataform.companyEmail
        )
      ) {
        errors.companydataform.companyEmail = "Correo electrónico invalido";
      }

      if (
        values.informacionpersonal &&
        values.informacionpersonal.celular &&
        values.informacionpersonal.celular.length != 10
      ) {
        errors.informacionpersonal.celular = "Número de celular invalido";
      }

      if (
        values.informacionpersonal &&
        values.informacionpersonal.phone &&
        !(values.informacionpersonal.phone.length === 10 || values.informacionpersonal.phone.length === 7)
      ) {
        errors.informacionpersonal.phone = "Número de teléfono invalido";
      }

      if (
        values.informacionpersonalcompany &&
        values.informacionpersonalcompany.celular &&
        values.informacionpersonalcompany.celular.length != 10
      ) {
        errors.informacionpersonalcompany.celular = "Número de celular invalido";
      }

      if (
        values.informacionpersonalcompany &&
        values.informacionpersonalcompany.phone &&
        !(values.informacionpersonalcompany.phone.length === 10 || values.informacionpersonalcompany.phone.length === 7)
      ) {
        errors.informacionpersonalcompany.phone = "Número de teléfono invalido";
      }

      if (
        values.companydataform &&
        values.companydataform.companyMobilePhone &&
        (values.companydataform.companyMobilePhone.length != 10)
      ) {
        errors.companydataform.companyMobilePhone =
          "Número de celular invalido";
      }

      if (
        values.companydataform &&
        values.companydataform.companyPhone &&
        !(values.companydataform.companyPhone.length === 10 || values.companydataform.companyPhone.length === 7)
      ) {
        errors.companydataform.companyPhone = "Número de teléfono invalido";
      }
      if (
        values.companydataform &&
        values.companydataform.habeasData &&
        values.companydataform.habeasData.value === false
      ) {
        errors.companydataform.habeasData =
          "Tiene que aceptar la Politica de tratamiento";
      }

      if (
        values.companydataform &&
        values.companydataform.companyDocument &&
        values.companydataform.companyDocument.length > 10
      ) {
        errors.companydataform.companyDocument =
          "Número de documento invalido";
      }

      if (
        values.informacionpersonal &&
        values.informacionpersonal.identificacion &&
        values.informacionpersonal.identificacion.length > 10
      ) {

        errors.informacionpersonal.identificacion =
          "Número de documento invalido";
      }

      if (
        values.informacionpersonalcompany &&
        values.informacionpersonalcompany.identificacion &&
        values.informacionpersonalcompany.identificacion.length > 10
      ) {

        errors.informacionpersonalcompany.identificacion =
          "Número de documento invalido";
      }

      let date = new Date();
      let fecha =
        (parseInt(date.getFullYear()) - 18).toString() + "-" + (parseInt(date.getMonth()) + 1).toString() + "-" + date.getDate();

      if (values.informacionpersonal && values.informacionpersonal.nacimiento) {
        const fechaSis = Date.parse(values.informacionpersonal.nacimiento);

        if (
          fechaSis < Date.parse((parseInt(date.getFullYear()) - 110).toString() + "-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.informacionpersonal.nacimiento = "Fecha de nacimiento invalida";
        }
      }
      if (values.informacionpersonalcompany && values.informacionpersonalcompany.nacimiento) {
        const fechaSis = Date.parse(values.informacionpersonalcompany.nacimiento);

        if (
          fechaSis < Date.parse((parseInt(date.getFullYear()) - 110).toString() + "-01-01") ||
          fechaSis > Date.parse(fecha)
        ) {
          errors.informacionpersonalcompany.nacimiento = "Fecha de nacimiento invalida";
        }
      }
    }


    validateForm(errors);
    return errors;
  };

  render() {
    const {
      updateAttributes,
      updateCompanyAttributes,
      updateFormType,
      tabSelected,
      message,
      error,
      logSuccess,
      hiddenDisable,
      selectedType,
      formInitiated,
      companyFormValues,
      peopleFormValues,
      createComfeTicket,
      handleUpload,
      handleRemove,
      Hclear,
      formFiles,
      dynamicOptions,
      dynamicDisable,
      charged,
      closeModal,
      reloadPage,
      contactoAdd,
      validar,
      typeForm,
      validateForm,
    } = this.props;

    const formTitle =
      selectedType.fieldValue === COMPANY_KEY
        ? COMPANY_FORM_TITLE
        : PEOPLE_FORM_TITLE;

    const { initUserData } = this.props;
    const user = getFromSession("authUser");
    if (user && !formInitiated) {
      initUserData(user);
    }

    function SimpleModal(contactoAdd, error, reloadPage, closeModal) {
      return (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open
        >
          <div className="messageSucess">
            <React.Fragment>
              {error ? (
                <Alert className="alert danger" variant="danger">
                  {contactoAdd.message}
                </Alert>
              ) : (
                <Alert className="alert success" variant="success">
                  {contactoAdd.message}
                </Alert>
              )}
            </React.Fragment>
            {reloadPage === false ?
              <div>
                <CircularProgress />
              </div>
              :
              <div>
                {contactoAdd.error ?
                  <Button
                    className='buttonFinalizar'
                    variant="text"
                    color="default"
                    onClick={() => closeModal()}
                  >
                    Continuar
                  </Button>
                  :
                  <Button
                    className='buttonFinalizar'
                    variant="text"
                    color="default"
                    onClick={() => window.location.reload(true)}
                  >
                    Finalizar
                  </Button>
                }
              </div>
            }
          </div>
        </Modal>
      );
    }
    function simpleAlert(message, error) {
      // const toast= toast.current.show({severity: 'info', summary: 'Success', detail: {message}});

      return (
        // <div>
        //     <Toast ref={toast}></Toast>
        // </div>
        <div className="messageError">
          <React.Fragment>
            {error ? (
              <Alert className="alert danger" variant="danger">
                {message}
              </Alert>
            ) : (
              <Alert className="alert success" variant="success">
                {message}
              </Alert>
            )}
          </React.Fragment>
        </div>
      );
    };


    return tabSelected === `tab-${TAB_LOG_IN}` ? (
      <div>
        {/* {logSuccess ? (
          <Redirect to="/home"></Redirect>
        ) : ( */}
        <div>

          <Card>
            <div className={hiddenDisable.header ? "no-visible" : ""}>
              <di>
                <h3>{"Selecciona el tipo de cliente"}</h3>
              </di>
              <React.Fragment>
                <CustomReduxForm
                  formName="companySelectButton"
                  className={"tab-select-form"}
                  items={[]}
                  validations={false}
                  handleSubmit={updateFormType}
                  buttons={getTabButton(updateFormType)}
                  dynamicOptions={dynamicOptions}
                  dynamicDisable={dynamicDisable}
                  hiddenDisable={hiddenDisable}
                  charged={charged}
                  handleUpload={handleUpload}
                  handleRemove={handleRemove}
                  Hclear={Hclear}
                />
              </React.Fragment>
              {/* <CustomForm
                formName="toggleSelectForm"
                items={TOOGLE_FORM}
                formTitle={false}
                className={`selectFormType ${selectedType.fieldValue}`}
                values={selectedType}
                dynamicDisable={dynamicDisable}
                updateAction={updateFormType}
                buttons={[]}
              /> */}
            </div>
            {/* <div className="form-text">
                                <h2>{`${FORM_HEADER} ${formTitle}`}</h2>
                                <p>{FORM_PARAGRAPH}</p>
                            </div> */}
            {/* <AuthData callbackPath="/contacto"> */}
            <div className={hiddenDisable.form ? "no-visible" : ""}>
              <Card.Body>

                {message ? simpleAlert(message, error) : ""}
                <div>
                  {selectedType.fieldValue === COMPANY_KEY ? (
                    <React.Fragment>
                      <CustomReduxForm
                        formName="companyContactForm"
                        items={getCompanyContactForm(
                          handleUpload,
                          handleRemove,
                          Hclear
                        )}
                        validations={this.validate}
                        handleSubmit={() =>
                          createComfeTicket({
                            body: companyFormValues,
                            type: COMPANY_KEY,
                            formFiles,
                          })
                        }
                        buttons={getLoginButton(() =>
                          createComfeTicket({
                            body: companyFormValues,
                            type: COMPANY_KEY,
                            formFiles,
                          })
                        )}
                        dynamicOptions={dynamicOptions}
                        dynamicDisable={dynamicDisable}
                        hiddenDisable={hiddenDisable}
                        charged={charged}
                        handleUpload={handleUpload}
                        handleRemove={handleRemove}
                        Hclear={Hclear}
                      />
                    </React.Fragment>
                  ) : (
                    <div>
                      <React.Fragment>
                        <CustomReduxForm
                          formName="peopleContactForm"
                          items={!typeForm ? peopleContactForm(
                            handleUpload,
                            handleRemove,
                            Hclear
                          ) : typeForm(
                            handleUpload,
                            handleRemove,
                            Hclear
                          )}
                          validations={this.validate}
                          handleSubmit={() =>
                            createComfeTicket({
                              body: peopleFormValues,
                              type: PEOPLE_KEY,
                              formFiles,
                            })
                          }
                          buttons={getLoginButton(() =>
                            createComfeTicket({
                              body: peopleFormValues,
                              type: PEOPLE_KEY,
                              formFiles,
                            })
                          )}
                          dynamicOptions={dynamicOptions}
                          dynamicDisable={dynamicDisable}
                          hiddenDisable={hiddenDisable}
                          charged={charged}
                          handleUpload={handleUpload}
                          handleRemove={handleRemove}
                          Hclear={Hclear}
                        />
                      </React.Fragment>

                      <div className="personasBanner"></div>
                    </div>
                  )}
                  <div>
                    {contactoAdd ? SimpleModal(contactoAdd, error, reloadPage, closeModal) : ''}
                  </div>
                </div>
              </Card.Body>
            </div>
            {/* </AuthData> */}
          </Card>
        </div>

      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hiddenDisable: getHiddenDisable(state),
    logSuccess: getLogSuccess(state),
    authForm: getAuthForm(state),
    selectedForm: getSelectedForm(state),
    selectedCompanyForm: getSelectedCompanyForm(state),
    tabSelected: getSelectedTab(state),
    selectedType: getSelectedType(state),
    message: getMessage(state),
    formInitiated: getFormInitiated(state),
    companyFormValues: getCompanyFormValues(state),
    peopleFormValues: getPeopleFormValues(state),
    formFiles: getFormFiles(state),
    dynamicOptions: getDynamicOptions(state),
    dynamicDisable: getDynamicDisable(state),
    charged: getCharged(state),
    reloadPage: getReloadPage(state),
    contactoAdd: getContactoAdd(state),
    error: getAuthError(state),
    cambio: getConsultaValidador(state),
    typeForm: getChangeType(state),
    validar: getValidar(state),
  };
};

const mapDispachToProps = (dispatch) => {
  return {
    updateAttributes: (value) => dispatch({ type: UPDATE_FIELD_ATTRIBUTE, value }),
    updateCompanyAttributes: (value) => dispatch({ type: UPDATE_COMPANY_FIELD_ATTRIBUTE, value }),
    selectTab: (value) => dispatch({ type: SELECT_TAB, value }),
    signUser: (value) => dispatch({ type: SIGN_USER, value }),
    updateFormType: (value) => dispatch({ type: SELECT_FORM_FIELD, value }),
    initUserData: (value) => dispatch({ type: INIT_USER_DATA, value }),
    createComfeTicket: (value) => dispatch({ type: CREATE_COMFE_TICKET, value }),
    handleUpload: (value) => dispatch({ type: UPLOAD_FILE, value }),
    handleRemove: (value) => dispatch({ type: REMOVE_FILE, value }),
    Hclear: (value) => dispatch({ type: CLEAR_FILE, value }),
    loadCatalog: (value) => dispatch({ type: LOAD_CATALOG, value }),
    loadCatalogCitis: (value) => dispatch({ type: REDUX_CHANGE, value }),
    closeModal: (value) => dispatch({ type: CLOSE_MODAL, value }),
    validateForm: (value) => dispatch({ type: VALIDATE_FORM, value }),
    selectForUrl: (value) => dispatch({ type: SELECT_FOR_URL, value }),
  };
};

export default connect(mapStateToProps, mapDispachToProps)(ComponentAuth);
