import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export class PieChartDemo extends Component {

    constructor(props) {
        super(props);

     

        this.lightOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            }
        };
    }

    getData(values){
        return {
            labels: values.label,
            datasets: [
                {
                    data: values.data,
                    backgroundColor: values.color,
                    hoverBackgroundColor: values.color,
                }
            ]
        };
    }

    render() {
        const { 
            dataGraph1, 
            titlePie,
        } = this.props;
        return (
            <div className="card p-d-flex p-jc-center">
                <h2>{titlePie}</h2>
                <Chart className="pieChar" type="pie" data={this.getData(dataGraph1)} options={this.lightOptions} style={{ position: 'relative', width: '90%' }} />
            </div>
        )
    }
}