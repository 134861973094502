import React, { Component } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Rating } from 'primereact/rating';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { dataGraph1 } from './actions';
import { TABLE_INTHOGAR } from './constants';
// import './DataTableDemo.css';

export class DataTableCrudDemo extends Component {

    emptyProduct = {
        id: null,
        name: '',
        image: null,
        description: '',
        category: null,
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };
    datasTable() {
    }

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            product: this.emptyProduct,
            selectedProducts: null,
            submitted: false,
            globalFilter: null,
            spandedRows: null,
            tipo: null,
            aux: null,
        };

        this.productService = this.datasTable();
        // this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);
        this.priceBodyTemplate = this.priceBodyTemplate.bind(this);
        this.ratingBodyTemplate = this.ratingBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.exportExcel = this.exportExcel.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.onRowExpand = this.onRowExpand.bind(this);
        this.onRowCollapse = this.onRowCollapse.bind(this);
        this.onCategoryChange = this.onCategoryChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNumberChange = this.onInputNumberChange.bind(this);
    }



    componentDidMount() {

    }

    formatCurrency(value) {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }

    onRowExpand(event) {
        this.toast.show({ severity: 'info', summary: 'Tabla expandida', detail: event.data.name, life: 2000 });
    }

    find_cellIndex(target) {
        if (target.cellIndex !== undefined) return target.cellIndex;
        if (target.parentElement) return this.find_cellIndex(target.parentElement);
    }
    onSelectionChange({ originalEvent, value }) {
        const cellIndex = this.find_cellIndex(originalEvent.target);
        if (cellIndex === 'index_of_row_with_buttons_you_dont_want_to_change') return
        else {
            //this.setState({selected_row:value})
            // console.log(value, originalEvent)
        }
    }

    rowExpansionTemplate(data) {
        var aux = this.state.tipo;
        var tem = this.state.aux;
        console.log(aux, tem, "data", data)

        return (

            <div className="orders-subtable">
                {data.inthogar?.length > 0 ?
                    <DataTable ref={(el) => this.dt = el} dataKey="numDoc" value={data.inthogar} className="tableinth">
                        {
                            TABLE_INTHOGAR?.map(item => {
                                if (item.field === "nombreInth") {
                                    return <Column field={item.field} header={item.header} sortable></Column>
                                } else {
                                    return <Column field={item.field} header={item.header} ></Column>
                                }

                            })
                        }
                    </DataTable>
                    : ""}
            </div>
        );
    }

    onRowCollapse(event) {
        this.toast.show({ severity: 'success', summary: 'Tabla Cerrada', detail: event.data.name, life: 2000 });
    }

    openNew() {
        this.setState({
            product: this.emptyProduct,
            submitted: false,
            productDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            productDialog: false
        });
    }



    createId() {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    exportExcel(data) {

        // const inth = []
        // data.forEach(element => {
        //     if (element.inthogar) {
        //         inth.push(element.inthogar);
        //     }
        // });
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, 'solicitudes');
        });
    }

    saveAsExcelFile(buffer, fileName) {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }



    onCategoryChange(e) {
        let product = { ...this.state.product };
        product['category'] = e.value;
        this.setState({ product });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let product = { ...this.state.product };
        product[`${name}`] = val;

        this.setState({ product });
    }

    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let product = { ...this.state.product };
        product[`${name}`] = val;

        this.setState({ product });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="New" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
                <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={this.confirmDeleteSelected} disabled={!this.state.selectedProducts || !this.state.selectedProducts.length} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate(props) {
        const { dataGraph1 } = props;
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={() => this.exportExcel(dataGraph1)} />
            </React.Fragment>
        )
    }

    imageBodyTemplate(rowData) {
        return <img src={`showcase/demo/images/product/${rowData.image}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />
    }

    priceBodyTemplate(rowData) {
        return this.formatCurrency(rowData.price);
    }

    ratingBodyTemplate(rowData) {
        return <Rating value={rowData.rating} readOnly cancel={false} />;
    }

    statusBodyTemplate(rowData) {
        return <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span>;
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={() => this.confirmDeleteProduct(rowData)} />
            </React.Fragment>
        );
    }

    actionTemplate (rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-file-pdf" style={{ background:"#fff",color:"#f70303",borderColor:"#f70303" }} className="p-button-rounded p-button-success p-mr-2" onClick={() => window.open("exportpdf/" +rowData.pdf, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,")} />
            </React.Fragment>
        );
    }

    render() {
        const {
            dataGraph1,
            dataTable,
            titleTable,
        } = this.props;

        //console.log(dataGraph1);

        const header = (
            <div className="table-header">
                <h5 className="p-m-0">{titleTable}</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Buscar..." />
                </span>
            </div>
        );


        return (
            <div className="datatable-crud-demo">
                <Toast ref={(el) => this.toast = el} />

                <div className="card">
                    <DataTable ref={(el) => this.dt = el} value={dataGraph1} scrollable scrollHeight="200px" // style={{ fontWeight: '700', width: '1200px' }}
                        dataKey="numDoc" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Observando {first} a {last} de {totalRecords} Solicitudes"
                        globalFilter={this.state.globalFilter} className="claseTablas"
                        onRowExpand={this.onRowExpand} onRowCollapse={this.onRowCollapse}
                        expandedRows={this.state.expandedRows}
                        onRowToggle={(e) => this.setState({ expandedRows: e.data, tipo: header, aux: this.dt })}
                        rowExpansionTemplate={this.rowExpansionTemplate}
                        header={header}>
                        {
                            dataTable?.map((item, i) => {
                                if (item.field === "pdf") {
                                    return <Column header={item.header} body={this.actionTemplate} style={{ textAlign: 'center', width: '10rem' }} />
                                }
                                if (item.field === "inthogar") { return <Column columnKey={item.field + i} cellIndex={i} className="inthogar" header={item.header} expander style={{ width: '3em' }} /> }
                                if (item.field === "ahorro") {
                                    return (
                                        <Column columnKey={item.field + i} cellIndex={i} className="ahorro" header={item.header}>
                                        </Column>
                                    );
                                }
                                if (item.field === "recursos") { return <Column columnKey={item.field + i} cellIndex={i} className="recursos" header={item.header} expander style={{ width: '4em' }} /> }
                                if (item.field === "observacion") { return <Column columnKey={item.field + i} cellIndex={i} className="observacion" field={item.field} header={item.header} /> }
                                if (item.field === "tipo" || item.field === "name") {
                                    return <Column columnKey={item.field + i} cellIndex={i} field={item.field} header={item.header} sortable></Column>
                                } else {
                                    return <Column columnKey={item.field + i} cellIndex={i} field={item.field} header={item.header} ></Column>
                                }

                            })
                        }


                    </DataTable>
                    {dataGraph1.length > 0 ?

                        <Toolbar className="p-mb-4" right={this.rightToolbarTemplate(this.props)}></Toolbar>
                        : ""}
                </div>
            </div>
        );
    }
}


// /* DataTableDemo.css */

// .datatable-crud-demo .table-header {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }

// .datatable-crud-demo .product-image {
//     width: 100px;
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
// }

// .datatable-crud-demo .p-dialog .product-image {
//     width: 150px;
//     margin: 0 auto 2rem auto;
//     display: block;
// }

// .datatable-crud-demo .confirmation-content {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }