import {
  INCREASE_STEP,
  SAVE_FINAL_SUCCESS,
  UPLOAD_FILE, REMOVE_FILE,
  UPDATE_INGRE_STEP2,
  UPDATE_DELETE_STEP2,
  UPDATE_TOTAL_STEP2,
  UPDATE_TOTAL_STEP4,
  UPDATE_DELETE_STEP4,
  UPDATE_INGRE_STEP4,
  CALL_DINAMIC_DISABLE,
  HIDDEN_DISABLE,
  CHARGED_VALIDATOR,
  ACTIVATE_RADIO,
  HIDDEN_CLOSE,
  OPTIONS_TABLE,
  ADD_RECURSO_AHORRO,
  DELETE_RECURSO_TABLE,
  CUENTAS_CREDITO,
  BASE64_ADD,
  BASE64_REMOVE,
  REMOVE_FIRMA,
  UPLOAD_FIRMA,
  CONTACTO_ADD,
  CLOSE_MODAL_F,
  RELOAD_PAGE,
  SAVE_REFFILES,
  GENERATE_PDF,
  ANEXOS_STORAGE,
  INIT_VALIDATE,
  REFERENCE_FILES,
  INCREASE_SIGUIENTE,
  CLOSE_MODAL_VALID,
  RELOAD_PDF,
  DATA_EMP_SUBSIDIO,
  SAVE_REFFIRMAS,
  OPTIONS_FIRMAS,
  GUARDAR_ITEMS,
  LIMPIAR_ADJUNTOS,
  INCREMENT_DECREMENT,
  ENABLED_DISABLED_ARRAY,
  REBOOT_FORM,
  SAVE_CATALOGO_CICLOS,
  CHANGE_STEP,
  INTEGRANTES_OBLIGATION,
  SAVE_TO_UPLOAD_FILE,
  STEP3_COMPLETO,
  OPEN_MODAL_ESPERA,
  SET_FIREBASE_USER_SESSION,
  SAVE_USER_COLABORADOR,
  ENABLE_ANEXOS_RESOURCES,
  ACTIONS_UPLOAD_ANEXOS_RESORSES,
  ACTIONS_TABLE_ANEXOS_RESORSES,
  CHANGE_UPDATE_UPLOAD_FILE,
  ACTIONS_FROM_FRAME_VIDEO,
  LIST_VIDEO_FRAME_SUCCESS,
  FORM_FIRMA_SUCCESS,
  UPDATE_DYNAMIC_DISABLED,
  SAVE_INTEGRANTES_OBLIGATORIOS,
  OPEN_MODAL_MESSAGE_CLOSE_FORM,
  CLEAN_UPLOAD_FIELDS,
  MODAL_MESSAGE_ALERT,
  CLOSE_MODAL_MESSAGE_ALERT,
  MANAGE_FILE_TYPE_VALID,
} from './constants';
import {
  FORM_NAME_STEP1,
  HANDLE_STEP,
  DECREASE_STEP,
  SAVE_ERASER_SUCCESS,
  OPEN_FINAL_MODAL,
  SUBMIT_FINAL_FORM,
  LOAD_CATALOG_SUCCESS,
  LOAD_DOCUMENT_SUCCESS,
  FORM_INTIALIZED,
  CHARGED_CATALOG,
  SAVE_FORM_FILES,
  CARGAR_CATALOGOS,
  DISABLED_GENERICOS,
  SAVE_DATA_EXT,
} from './constants';


export const initialState = {
  formId: FORM_NAME_STEP1,
  step: 0,
  showSnackBar: false,
  idFormulario: false,
  steps: 5,
  openModal: false,
  hiddenClose: {
    otrorecursoA: true,
    otrorecursoR: true,
  },
  base64Add: [],
  referenceFiles: [],
  refFiles: {},
  addRecursoAhorro: [],
  addRecursoComple: [],
  optionsInthogar: OPTIONS_FIRMAS,
  optionsTable: {
    firma2: [],
    tableAhorro: [],
    laborandoSelect: [],
    subsidioSelect: [],
  },
  reloadPage: false,
  contactoAdd: false,
  enableDisableArray: false,
  error: false,
  modalCloseForm: false,
  chargedValidator: false,
  activateRadio: false,
  tempDisableCampo: [],
  anexosStorage: {},
  dynamicOptions: {
    cityPostu: [],
    laborandoSelect: [],
    subsidioSelect: [],
    companyTable: [],
    fotocopyCedula: [],
    certificadoAhorro: [],
    cartaLaboral: [],
    certificadoCesantias: [],
    resolucionPensional: [],
    certificadoConstructora: [],
    cartaAprobacion: [],
    certificadoMedico: [],
    certificadoTradicion: [],
    certificadoIcbf: [],
  },
  dynamicDisable: DISABLED_GENERICOS,
  dataEmpSubsidio: {},
  hiddenDisable: {
    modalidadvivienda: false,
    valorconstruccion: false,
    otrorecursoA: false,
    companyTable: true,
    companyTableLabor: true,
    companyJobName: true,
    companyJobAddress: true,
    companyPhoneJob: true,
    entidadCredito: true,
    dateCredito: true,
  },
  formValid: false,
  enabledAnexos: false,
  itemSaved: {},
  generatePdf: {},
  hoodId: false,
  initValid: false,
  messageAlert: false,
  formInitialized: false,
  userid: false,
  formFiles: [],
  formFirmas: [],
  formFileData: [],
  reloadPdf: false,
  message: false,
  rebootFiles: false,
  catalogoCiclos: false,
  integrantesObligatorios: false,
  inthObligatorios: [],
  step3completo: false,
  refFirmas: [],
  charged: {
    goal: false,
    site: false,
    idMuniResidencia: false,
  },
  ingreStep2: { ingresos: {}, relative: [] },
  ingreStep4: { ahorroPrevio: [], recurComple: [] },
  firebaseUser: {
    accessToken: "charging"
  },
  firebaseUserInfo: false,
  userColaborador: false,
  filesAnexosResources: false,
  updateUploadFile: false,
  openFrameVideo: false,
  openTabFrameVideo: false,
  firmaLabel: {},
  listVideoFrame: {
    _0: "https://www.youtube.com/embed/DL8nKLUoQyw?si=vyG5bDSBXOfxt6JT",
    _1: "https://www.youtube.com/embed/O-u-cLDJApo?si=DYF0XcvdgNBGDKq0",
    _2: "https://www.youtube.com/embed/DL8nKLUoQyw?si=vyG5bDSBXOfxt6JT",
    _3: "https://www.youtube.com/embed/O-u-cLDJApo?si=DYF0XcvdgNBGDKq0",
    _4: "https://www.youtube.com/embed/DL8nKLUoQyw?si=vyG5bDSBXOfxt6JT",
    _5: "https://www.youtube.com/embed/O-u-cLDJApo?si=DYF0XcvdgNBGDKq0",
  },
};

export const reducer = (state = initialState, action) => {
  let newState = { ...state };
  const { payload, type, value } = action;
  newState.showSnackBar = false;
  newState.openModal = false;
  //newState.enableDisableArray = false;

  switch (type) {
    case INCREASE_SIGUIENTE:
      if (payload.state === true) {
        newState.step = newState.step < newState.steps ? newState.step + 1 : newState.steps;
      } else {
        newState.formValid = payload.message;
      }

      return {
        ...newState
      };

    case INCREMENT_DECREMENT:
      if (payload) {
        newState.step = newState.step + 1;
      } else {
        newState.step = newState.step - 1;
      }

      return {
        ...newState
      };

    case SAVE_REFFIRMAS:
      if (payload.state) {
        newState.formFirmas = []
      }
      newState.refFirmas = payload.data;


      return {
        ...newState
      };

    case INIT_VALIDATE:
      newState.initValid = payload;
      return { ...newState };

    case CLOSE_MODAL_MESSAGE_ALERT:
      newState.messageAlert = false;
      return { ...newState };

    case MODAL_MESSAGE_ALERT:
      newState.messageAlert = payload;
      return { ...newState };

    case STEP3_COMPLETO:
      newState.step3completo = payload;
      return { ...newState };

    case INTEGRANTES_OBLIGATION:
      newState.integrantesObligatorios = payload;
      return { ...newState };

    case SAVE_INTEGRANTES_OBLIGATORIOS:
      newState.inthObligatorios = payload;
      return { ...newState };

    case SAVE_DATA_EXT:
      newState.hoodId = payload;

      return { ...newState };

    case CLOSE_MODAL_VALID:
      newState.formValid = false
      return { ...newState }

    case ENABLE_ANEXOS_RESOURCES:
      newState.enabledAnexos = payload;
      return { ...newState }

    // case MANAGE_FILE_TYPE_VALID:
    //   console.log('manageFileType', action)
    //   //newState.enabledAnexos = payload;
    //   return { ...newState }

    case SAVE_USER_COLABORADOR:
      newState.userColaborador = payload
      return { ...newState }

    case ACTIONS_UPLOAD_ANEXOS_RESORSES:
      if (value.type === "upload") {
        newState.filesAnexosResources = value;
      } else
        newState.filesAnexosResources = false;
      return { ...newState }

    case CLEAN_UPLOAD_FIELDS:
      newState.filesAnexosResources = false;
      if (payload && payload.data) {

      } else
        newState.enabledAnexos = false;
      newState.showSnackBar = !newState.showSnackBar
      return { ...newState }

    case ACTIONS_TABLE_ANEXOS_RESORSES:
      newState.actionTableAnexos = payload;
      return { ...newState }

    case CHANGE_UPDATE_UPLOAD_FILE:
      newState.updateUploadFile = payload;
      return { ...newState }

    case GENERATE_PDF:
      newState.generatePdf = payload;

      return {
        ...newState
      };

    case RELOAD_PDF:
      newState.reloadPdf = true;

      return {
        ...newState
      };

    case GUARDAR_ITEMS:
      newState.itemSaved = payload;

      return {
        ...newState
      };

    case DECREASE_STEP:
      newState.step = newState.step > 0 ? newState.step - 1 : 0;

      return {
        ...newState
      };

    case HANDLE_STEP:
      newState.step = action.value;
      return {
        ...newState
      };

    case CHANGE_STEP:
      newState.step = payload;
      return {
        ...newState
      };

    case OPEN_MODAL_ESPERA:
      newState.modalChargedEspera = payload;
      return {
        ...newState
      };

    case SAVE_ERASER_SUCCESS:
      newState.idFormulario = payload;
      return {
        ...newState
      };

    case OPEN_FINAL_MODAL:
      newState.openModal = true;
      return { ...newState };

    case LIST_VIDEO_FRAME_SUCCESS:
      newState.listVideoFrame = payload;
      return { ...newState };

    case CLOSE_MODAL_F:
      newState.openModal = false;
      newState.contactoAdd = false;
      newState.step = 5;
      return {
        ...newState
      };

    case CONTACTO_ADD:
      newState.contactoAdd = payload.message;
      newState.error = payload.error;

      return {
        ...newState
      }

    case OPEN_MODAL_MESSAGE_CLOSE_FORM:
      newState.modalCloseForm = payload;
      newState.openModal = false;
      newState.contactoAdd = false;
      return {
        ...newState
      }

    case DATA_EMP_SUBSIDIO:
      newState.dataEmpSubsidio = payload;
      return {
        ...newState
      }
    case RELOAD_PAGE:
      newState.reloadPage = payload.reload;


      return {
        ...newState
      }


    case LOAD_CATALOG_SUCCESS:
      newState.dynamicOptions = payload;

      return {
        ...newState
      };

    case FORM_INTIALIZED:
      const { userid, idFormulario } = payload;
      newState.formInitialized = true;
      newState.idFormulario = idFormulario;
      newState.userid = userid;

      return {
        ...newState
      };

    case FORM_FIRMA_SUCCESS:
      newState.firmaLabel[payload.field] = payload.data;
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      };

    case UPLOAD_FIRMA:
      newState.dynamicDisable[value.name] = true;
      newState.showSnackBar = !newState.showSnackBar;

      return { ...newState }

    case UPDATE_DYNAMIC_DISABLED:
      newState.dynamicDisable = payload;
      newState.showSnackBar = !newState.showSnackBar;

      return { ...newState }

    case REMOVE_FIRMA:
      newState.dynamicDisable[value.name] = false;
      newState.showSnackBar = false;
      // const removedFil = value.event.file;
      // if (value.event) {
      //   newState.formFirmas = newState.formFirmas.filter(item => {
      //     return item.fieldName === value.fileData.name
      //       && item.formName === value.fileData.formName
      //       && item.fileName !== removedFil.name;
      //   });
      // }

      return {
        ...newState
      }

    case SAVE_TO_UPLOAD_FILE:
      var n = payload.fileData.name;
      if (n) {
        if (n === "certificadoConstructora" || n === "certificadoIngresos" || n === "certificadoIcbf" || n === "cartaAprobacion") {
          // newState.dynamicDisable[n] = true;
        }
      }

      if (payload) {
        payload.files.map(uploadedFile => {
          newState.formFiles.push({
            file: uploadedFile,
            fieldName: payload.fileData.name,
            fileName: uploadedFile.name,
            formName: payload.fileData.formName
          })
        });

      }
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      }

    case REMOVE_FILE:
      newState.dynamicDisable[value.fileData.name] = false;
      newState.showSnackBar = !newState.showSnackBar;
      const removedFile = value.event.file.lastModified;
      if (value.event) {
        newState.formFiles = newState.formFiles.filter(item => {
          return item.file.lastModified !== removedFile;
        });
      }

      return {
        ...newState
      }
    case LOAD_DOCUMENT_SUCCESS:
      newState.dynamicOptions[payload.field] = payload.options;
      return {
        ...newState
      }

    case UPDATE_INGRE_STEP2:

      if (payload.clave === "inicio") {
        newState.ingreStep2.relative = payload.value;
      }

      if (payload.field) {
        const remove = payload.field;
        const name = payload.field.split(".");
        if (name[1] === "ingresos") {
          newState.ingreStep2.ingresos = { valor: payload.valor };
        } else if (name[1] === "relative") {
          newState.ingreStep2.relative = newState.ingreStep2.relative.filter(item => {
            return item.valor
              && item.field !== remove;
          });
          newState.ingreStep2.relative.push({
            valor: payload.valor,
            field: payload.field
          })


        }
      }
      return {
        ...newState
      }

    case UPDATE_DELETE_STEP2:
      const removes = payload.field;
      newState.ingreStep2.relative = newState.ingreStep2.relative.filter(item => {
        return item.valor
          && item.field !== removes;
      });
      return {
        ...newState
      }

    case UPDATE_TOTAL_STEP2:
      newState.ingreStep2.totaActual = { valor: payload.valor };

      return {
        ...newState
      }

    case UPDATE_INGRE_STEP4:
      if (payload.clave === "inicioA") {
        newState.ingreStep4.ahorroPrevio = payload.value;
      }
      if (payload.clave === "inicioR") {
        newState.ingreStep4.recurComple = payload.value;
      }
      if (payload.field) {
        const removeA = payload.field;
        const nameA = payload.field.split(".");
        if (nameA[1] === "safeValueA") {

          newState.ingreStep4.ahorroPrevio = newState.ingreStep4.ahorroPrevio.filter(item => {
            return item.valor
              && item.field !== removeA;
          });
          newState.ingreStep4.ahorroPrevio.push({
            valor: payload.valor,
            field: payload.field
          })
        }
        if (nameA[1] === "safeValueR") {

          newState.ingreStep4.recurComple = newState.ingreStep4.recurComple.filter(item => {
            return item.valor
              && item.field !== removeA;
          });
          newState.ingreStep4.recurComple.push({
            valor: payload.valor,
            field: payload.field
          })
        }
      }


      return {
        ...newState
      }

    case UPDATE_DELETE_STEP4:
      const removeAs = payload.field
      const remov = payload.field.split('.');
      if (remov[1] === "safeValueA") {
        newState.ingreStep4.ahorroPrevio = newState.ingreStep4.ahorroPrevio.filter(item => {
          return item.valor
            && item.field !== removeAs;
        });
      }
      if (remov[1] === "safeValueR") {
        newState.ingreStep4.recurComple = newState.ingreStep4.recurComple.filter(item => {
          return item.valor
            && item.field !== removeAs;
        });
      }


      return {
        ...newState
      }

    case UPDATE_TOTAL_STEP4:
      newState.ingreStep4.totaAhorro = { valor: payload.valor };

      return {
        ...newState
      }

    case SAVE_FINAL_SUCCESS:
      newState = {
        formId: FORM_NAME_STEP1,
        step: 0,
        showSnackBar: false,
        idFormulario: false,
        modalChargedEspera: false,
        steps: 5,
        openModal: false,
        dynamicOptions: {},
        formInitialized: true,
        userid: false,
        formFiles: []
      }
      return {
        ...newState
      }

    case CARGAR_CATALOGOS:
      newState.catalogos = payload;

      return {
        ...newState
      }

    case SAVE_REFFILES:
      newState.refFiles = payload;

      return {
        ...newState
      }

    case SAVE_CATALOGO_CICLOS:
      newState.catalogoCiclos = payload;

      return {
        ...newState
      }



    case HIDDEN_DISABLE:
      newState.hiddenDisable[payload.field] = payload.state;
      return {
        ...newState
      }

    case HIDDEN_CLOSE:

      newState.showSnackBar = !newState.showSnackBar;
      newState.dynamicDisable[payload.val] = payload.state;
      return {
        ...newState
      }

    case ADD_RECURSO_AHORRO:
      if (payload) {
        if (payload.clave) {
          if (payload.field === "ahorro") {
            newState.addRecursoAhorro = payload.data;
          } else {
            newState.addRecursoComple = payload.data;
          }

        } else {
          if (payload.field === "ahorro") {
            newState.addRecursoAhorro.push(payload.data);
          } else {
            newState.addRecursoComple.push(payload.data);
          }
        }
      }




      return {
        ...newState
      }

    case DELETE_RECURSO_TABLE:
      const { event, row, field } = value;
      if (event) {

        if (field?.name && field?.name === "tableAhorro") {
          newState.addRecursoAhorro = newState.addRecursoAhorro.filter(item => {
            return item && !(item[0] === row[0]
              && item[1] === row[1]
              && item[2] === row[2]
              && item[3] === row[3]);
          });
        }
        if (field?.name && field?.name === "tableRecurso") {
          newState.addRecursoComple = newState.addRecursoComple.filter(item => {
            return item && item[0] !== row[0]
              && item[3] !== row[3];
          });
        }

      }


      return {
        ...newState
      }

    case CHARGED_VALIDATOR:
      newState.chargedValidator = payload.state;

      return {
        ...newState
      }

    case ACTIONS_FROM_FRAME_VIDEO:
      newState.openTabFrameVideo = !newState.openTabFrameVideo;
      newState.openFrameVideo = !newState.openFrameVideo;

      return {
        ...newState
      }

    case LIMPIAR_ADJUNTOS:
      newState.formFiles = [];
      newState.formFirmas = [];

      return {
        ...newState
      }

    case ANEXOS_STORAGE:
      newState.dynamicOptions[payload.field] = payload.data;
      const nField = payload.field;
      if (nField === "certificadoConstructora" || nField === "certificadoIngresos" || nField === "certificadoIcbf" || nField === "cartaAprobacion") {
        newState.dynamicDisable[nField] = payload.state;
      } else {
        newState.dynamicDisable[nField] = false;
      }
      if (payload.firma) {
        newState.dynamicDisable[payload.field] = payload.state;
      }
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      }

    case ACTIVATE_RADIO:
      newState.activateRadio = payload.state;

      return {
        ...newState
      }

    case BASE64_ADD:
      newState.base64Add.push(payload);
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      }

    case REFERENCE_FILES:
      newState.referenceFiles = payload;

      return {
        ...newState
      }

    case SET_FIREBASE_USER_SESSION:
      newState.firebaseUser = payload.userInfo;
      newState.firebaseUserInfo = payload.authUserInfo;
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      }

    case REBOOT_FORM:
      newState.rebootFiles = payload;

      return {
        ...newState
      }

    case ENABLED_DISABLED_ARRAY:
      newState.enableDisableArray = payload;

      return {
        ...newState
      }

    case BASE64_REMOVE:
      newState.base64Add = payload;
      payload.map((element) => {
        var n = element.nameFil;
        if (n === "certificadoConstructora" || n === "certificadoIngresos" || n === "certificadoIcbf" || n === "cartaAprobacion") {

        }
      });

      return {
        ...newState
      }

    case OPTIONS_TABLE:
      newState.optionsInthogar[payload.field] = payload.data;
      newState.dynamicOptions[payload.field] = payload.data;
      newState.message = "cambios";

      return {
        ...newState
      }

    case CALL_DINAMIC_DISABLE:
      if (payload.tipo) {

        const aux = payload.field;
        aux.map((element) => {
          newState.dynamicDisable[element] = payload.state;
        });
      } else {
        newState.dynamicDisable[payload.field] = payload.state;
      }
      newState.showSnackBar = !newState.showSnackBar;
      return {
        ...newState
      }

    default:
      return {
        ...newState
      };
  }
};
