import {
  GET_CONVERSATIONS_SUCCESS,
  CONV_HEADERS
} from './constants';
  
  export const initialState = {
    conversations: [],
    convHeaders: CONV_HEADERS
  };
  
  const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type } = action;

    switch (type) {
      case GET_CONVERSATIONS_SUCCESS:
          const { data  } = payload;
          newState.conversations = data;

          return {
            ...newState
          };
      default:
        return {
          ...newState
        };
    }
  };
  
  export default reducer;
  