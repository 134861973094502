import { createStructuredSelector } from 'reselect';
import { getFormValues, } from 'redux-form' // ES6;


export const getCompanyFormValues = (state) => getFormValues("formDeportesVacaciones")(state);

export const getMessage = (state) => state.localReducer.message;
export const getAuthError = (state) => state.localReducer.error;

export const getDynamicOptions = (state) => state.localReducer.dynamicOptions;
export const getCatalogosCargados = (state) => state.localReducer.catalogos;
export const getCharged = (state) => state.localReducer.charged;
export const getDynamicDisable = (state) => state.localReducer.dynamicDisable;
export const getContactoAdd = (state) => state.localReducer.contactoAdd;
export const getReloadPage = (state) => state.localReducer.reloadPage;
export const getDiciplina = (state) => state.localReducer.diciplina;
export const getFileBase64 = (state) => state.localReducer.base64;
export const getEsperaCargue = (state) => state.localReducer.esperaCargue;
export const getSnackBar = (state) => state.localReducer.showSnackBar;

export const authSelector = createStructuredSelector({
    // authForm: getAuthForm,
    // selectedFotm: getSelectedForm,
    // tabSelected: getSelectedTab,
    message: getMessage,
    error: getAuthError,
    // logSuccess: getLogSuccess,
});