import HomePage from './pages/home';
import ContactosPage from './pages/contactos';
import ViviendaPage from './pages/vivienda';
import ConversationPage from './pages/conversation';
import AdminComponent from './pages/admin';
import IndexPage from './pages/exportpdf';
import DeportesPage from './pages/deportes';

import { 
    faHome, 
    faUsers,
} from "@fortawesome/free-solid-svg-icons";


export const GET_USERS_API = 'users/';
export const GET_USER_API = 'users/2';
export const PATH_API = 'https://reqres.in/api/';
export const COLLECTION_USER_NAME = 'users'; 
export const paths = [
    { path: '/home', component: HomePage },
    { path: '/vivienda', component: ViviendaPage },
    { path: '/conversation', component: ConversationPage },
    { path: '/contacto', component: ContactosPage },
    { path: '/informes', component: AdminComponent },
    { path: '/exportpdf/:formid?', component: IndexPage },
    { path: '/deportes', component: DeportesPage },
];


export const menuItems = [ 
    {
        path: '/home',
        name: 'Home',
        css: faHome,
        key: 1,
        label: 'Home'
    },
    {
        path: '/auth',
        name: 'User',
        css: faUsers,
        key: 2,
        label: 'Usuarios',
    },
    {
        path: '/conv',
        name: 'Conversation',
        css: faUsers,
        key: 3,
        label: 'Conversations',
    }
]

export const main = ContactosPage;