import {
    GET_USERS_REAL_TIME,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
    FILTER_USERS_SUCCESS,
    PAGINATE_USERS_SUCCESS
} from './constants'

export function getUsers (payload) {
    return {
        type: GET_USERS_REAL_TIME,
        payload
    };
}

export function getUsersSuccess (payload) {
    return {
        type: GET_USERS_SUCCESS,
        payload
    };
}

export function getUsersError (payload) {
    return {
        type: GET_USERS_ERROR,
        payload
    };
}

export function filterUserSuccess (payload) {
    return {
        type: FILTER_USERS_SUCCESS,
        payload
    };
}

export function paginateUserSuccess (payload) {
    return {
        type: PAGINATE_USERS_SUCCESS,
        payload
    };
}