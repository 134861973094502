import {
    CALL_DINAMIC_DISABLE,
    CARGAR_CATALOGOS,
    CLOSE_MODAL,
    CONTACTO_ADD,
    LOAD_CATALOG_SUCCESS,
    LOAD_DOCUMENT_SUCCESS,
    RELOAD_PAGE,
    SAVE_BASE64_PDF,
    SELECT_DICIPLINA,
    SELECT_OPORTUNIDAD_URL,
    LOAD_CATALOG,
    INIT_MODAL_ESPERA,
    CALL_DINAMIC_DISABLE_LOTE,
    CONTACTO_ADD_COMPONENT,
} from './constants';

export const initialState = {
    error: false,
    message: false,
    dynamicOptions: {},
    dynamicDisable: {
        age: true,
        oportunidad: true,
    },
    catalogos: {},
    charged: false,
    contactoAdd: false,
    reloadPage: false,
    diciplina: false,
    base64: false,
    esperaCargue: false,
    showSnackBar: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type, value } = action;
    newState.message = false;
    newState.error = false;
    newState.loadingCatalog = false;
    newState.showSnackBar = false;
    switch (type) {

        case LOAD_CATALOG_SUCCESS:
            newState.dynamicOptions = payload;
            return {
                ...newState
            }

        case CARGAR_CATALOGOS:
            newState.catalogos = payload;
            return {
                ...newState
            }
        case INIT_MODAL_ESPERA:
            newState.esperaCargue = payload;
            return {
                ...newState
            }

        case CLOSE_MODAL:
            newState.contactoAdd = false
            return { ...newState }

        case SELECT_OPORTUNIDAD_URL:

            return { ...newState }

        case CONTACTO_ADD:
            newState.contactoAdd = payload;
            newState.error = payload.error;
            return { ...newState }

        case CONTACTO_ADD_COMPONENT:
            newState.reloadPage = true;
            newState.contactoAdd = value;
            newState.error = value.error;
            return { ...newState }

        case SAVE_BASE64_PDF:
            newState.base64 = payload;
            return { ...newState }

        case RELOAD_PAGE:
            newState.reloadPage = payload.reload;
            return { ...newState }

        case SELECT_DICIPLINA:
            newState.diciplina = payload;

            return { ...newState }

        case CALL_DINAMIC_DISABLE:
            if (payload.field === "answerMedicamento") {
                newState.dynamicDisable["dosis"] = payload.state;
            }
            newState.dynamicDisable[payload.field] = payload.state;
            return {
                ...newState
            }

        case CALL_DINAMIC_DISABLE_LOTE:
            payload.map(e => {
                newState.dynamicDisable[e.field] = e.state;
            });
            newState.showSnackBar = !newState.showSnackBar;
            return { ...newState };

        case LOAD_DOCUMENT_SUCCESS:
            newState.message = true;
            newState.dynamicOptions[payload.field] = payload.options;


            return {
                ...newState
            }

        default:
            return {
                ...newState
            };
    }
};

export default reducer;
