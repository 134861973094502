import React, { Component } from 'react';
import { Chart } from 'primereact/chart';

export class LineChartDemo extends Component {
    

    constructor(props) {
        super(props);

        this.options = this.getLightTheme();
    }

    getLightTheme() {
        
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            basicOptions
        }
    }
    
    render() {
        const { 
            dataGraph1, 
            titleLine,
        } = this.props;
        const {  basicOptions } = this.options;
        //console.log(dataGraph1)
        
        return (
            <div>
                <div className="card">
                    <h5>{titleLine}</h5>
                    <Chart type="line" data={dataGraph1} options={basicOptions} />
                </div>
                
            </div>

            
        )
    }
}