import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';

import { COMPONENT_TABS } from "./constants";
import DashContacto from './dashContacto';
import DashVivienda from './dashVivienda';
import DashRecreacion from './dashRecreacion';
import LinearProgress from "@material-ui/core/LinearProgress";
import Alert from "@material-ui/lab/Alert";
import Modal from '@material-ui/core/Modal';
import { getCargandoModal } from './selector';
import LoginButton from "../../components/authlogin/authlogin";


class Dashboard extends Component {
  renderTabContent(tab) {
    let currentTabComponent = false;

    switch (tab) {
      case 0:
        currentTabComponent = <DashVivienda />;
        break;

      case 1:
        currentTabComponent = <DashContacto />;
        break;

      case 2:
        currentTabComponent = <DashRecreacion />;
        break;

      default:
        break;
    }

    return currentTabComponent;
  }

  modalSubmitValid(props) {
    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="loading-info-dash">
        <div>
          Cargando información ...
        </div>
        <LinearProgress />
      </div>
      </Modal>
      
    );
  }

  render() {
    const { tab, handleChange, logoutAction,cargandoModal } = this.props;
    return (
    
      <React.Fragment>
        <div className="principal-wrapper">
          {cargandoModal ? this.modalSubmitValid(this.props):""}
          <div className="header-wrapper">
            <h2 className="title">Modulo de estadísticas</h2>
            <p className="parrafo">Aquí puede consultar las estadísticas de cada uno de los formularios</p>
            {/* <div className='button-wrapper'>
              <Button variant="outlined" color='primary' className='btn-exit' onClick={logoutAction} >
                Cerrar Sesión
              </Button>
            </div> */}
          </div>
          <Paper square>
            <Tabs
              className='tabs_container'
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              {
                COMPONENT_TABS.map((item, i) => {
                  return <Tab key={i} label={item} />
                })
              }
            </Tabs>
          </Paper>
          <Paper className='content_tab' square>
            {this.renderTabContent(tab)}
          </Paper>

        </div>
      </React.Fragment>
      // </LoginButton>
    );
  }

}
const mapStateToProps = state => {
  return {
    cargandoModal:getCargandoModal(state),
      //cuerrentTab: getCurrentTab(state),
};
}

const mapDispachToProps = dispatch => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(Dashboard);
