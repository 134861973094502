import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import CustomTable from "../../components/table/table";
import { getConversations } from "./selectors";
import { Spinner, Alert } from 'react-bootstrap';
import {
    GET_CONVERSATIONS
} from './constants';


class ComponentHome extends Component {

    componentDidMount () {
        const { getConversations } = this.props;
        getConversations();
    }

    render() {
        const {
            convHeaders, 
            conversations,
        } = this.props;

        return (
            <div className="App conversation-container">
                <div className='conversation-list_wrapper'>
                { conversations && !conversations.empty ? 
                    <CustomTable 
                        className="conversations-table" 
                        items={conversations} 
                        headers={convHeaders}
                        onSelectRow={false}
                    /> :
                    <Spinner className="spinner-wrapper" animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        convHeaders: state.convHeaders,
        conversations: getConversations(state),
    };
};

const mapDispachToProps = dispatch => {
    return { 
        getConversations: () => dispatch({ type: GET_CONVERSATIONS, value: 1 })
    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(ComponentHome);