import { 
    faHome, 
    faUsers, 
    faFileAlt, 
    faAngleDoubleRight, 
    faUserCircle, 
    faSignOutAlt,
    faBookReader,
    faBookDead,
    faBookMedical,
    faBookmark,
    faAddressBook
} from "@fortawesome/free-solid-svg-icons";

export const ITEM_TITLE = [
    {
        position: 'right',
        path: '/',
        name: 'Asimov',
        css: faAngleDoubleRight,
        key: 4,
        label: 'Asimov',
        className:'logo',
        active: false,
    }
]

export const getItemLogout = (logoutAction) => [
    {
        position: 'left',
        path: '/',
        name: 'Exit',
        css: faSignOutAlt,
        key: 5,
        label: 'Exit',
        className:'logout',
        active: false,
        onItemClick: logoutAction,
    }
]

export const getUserItem = (name, picture) => {
    const ITEM_PROFILE = [
        {
            
            position: 'left',
            path: '/profile',
            name: name,
            css: faUserCircle,
            key: 6,
            label: name,
            className:'',
            active: false,
            picture: picture
        }
    ]

    return ITEM_PROFILE;
}