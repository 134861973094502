import {
    UPDATE_FIELD_ATTRIBUTE,
    SELECT_TAB,
    SIGN_USER,
    LOG_USER,
    AUTH_SUCCESS,
    AUTH_ERROR,
    AUTH_LOGIN_SUCCESS,
    SELECT_FORM_FIELD,
    INIT_USER_SUCCESS,
    INIT_CONTACTO_SUCCESS,
    INIT_CONTACTO_ERROR,
    UPLOAD_FILE_REDUCER,
    LOAD_CATALOG_SUCCESS,
    LOAD_DOCUMENT_SUCCESS,
    REMOVE_FILE,
    CHARGED_CATALOG,
    CONTACTO_ADD,
    SAVE_BD,
    RELOAD_PAGE,
    BASE_64,
    CARGAR_CATALOGOS,
    CARGAR_CARGO,
    DATA_AUTH,
    CALL_DINAMIC_DISABLE,
    BASE64_ADD,
    BASE64_REMOVE,
    VALIDATE_FORM,
    CALL_DISABLED,
    SELECT_FOR_URL,
    SAVE_CONSULTA_VALIDADOR,
    DISABLE_PEOPLE_FORM,
    CHANGE_TYPE,
    
} from './constants'

export function updateAttributes (payload) {
    return {
        type: UPDATE_FIELD_ATTRIBUTE,
        payload
    };
}

export function selectedType (payload) {
    return {
        type: SELECT_FORM_FIELD,
        payload
    };
}

export function selectTab (payload) {
    return {
        type: SELECT_TAB,
        payload
    };
}

export function signUser (payload) {
    return {
        type: SIGN_USER,
        payload
    };
}

export function logUSer (payload) {
    return {
        type: LOG_USER,
        payload
    };
}

export function authSuccess (payload) {
    return {
        type: AUTH_SUCCESS,
        payload
    };
}

export function logSuccess (payload) {
    return {
        type: AUTH_LOGIN_SUCCESS,
        payload
    };
}

export function authError (payload) {
    return {
        type: AUTH_ERROR,
        payload
    };
}

export function initUserSuccess (payload) {
    return {
        type: INIT_USER_SUCCESS,
        payload
    };
}

export function initContactSuccess (payload) {
    return {
        type: INIT_CONTACTO_SUCCESS,
        payload
    };
}

export function initContactError (payload) {
    return {
        type: INIT_CONTACTO_ERROR,
        payload
    };
}

export function uploadReducer (payload) {
    return {
        type: UPLOAD_FILE_REDUCER,
        payload
    };
}
export function deleteUpload(payload) {
    return {
        type: REMOVE_FILE,
        payload
    };
}

export function loadCatalogSuccess (payload) {
    return {
        type: LOAD_CATALOG_SUCCESS,
        payload
    };
}
export function loadDocumentSuccess (payload) {
    return {
        type: LOAD_DOCUMENT_SUCCESS,
        payload
    };
}
export function chargedCatalog (payload) {
    return {
        type: CHARGED_CATALOG,
        payload
    };
}

export function contactoAdd (payload) {
    return {
        type: CONTACTO_ADD,
        payload
    };
}
export function reloadPage (payload) {
    return {
        type: RELOAD_PAGE,
        payload
    };
}

export function guardarBD (payload) {
    return {
        type: SAVE_BD,
        payload
    };
}



export function crearBase64 (payload) {
    return {
        type: BASE_64,
        payload
    };
}

export function cargarCatalogos (payload) {
    return {
        type: CARGAR_CATALOGOS,
        payload
    };
}

export function cargarCargo (payload) {
    return {
        type: CARGAR_CARGO,
        payload
    };
}

export function dataAuth (payload) {
    return {
        type: DATA_AUTH,
        payload
    };
}

export function callDinamicDisable (payload) {
    return {
        type: CALL_DINAMIC_DISABLE,
        payload
    };
}

export function base64Remove(payload) {
    return {
        type: BASE64_REMOVE,
        payload
    };
}

export function base64Add(payload) {
    return {
        type: BASE64_ADD,
        payload
    };
}

export function validateForm(payload) {
    return {
        type: VALIDATE_FORM,
        payload
    };
}

export function callDisabled(payload) {
    return {
        type: CALL_DISABLED,
        payload
    };
}

export function selectForUrl(payload) {
    return {
        type: SELECT_FOR_URL,
        payload
    };
}

export function saveConsultaValidador (payload) {
    return {
        type: SAVE_CONSULTA_VALIDADOR,
        payload
    };
}

export function disablePeopleForm (payload) {
    return {
        type: DISABLE_PEOPLE_FORM,
        payload
    };
}

export function changeType (payload) {
    return {
        type: CHANGE_TYPE,
        payload
    };
}

