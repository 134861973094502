import React from 'react'

import { Form, InputGroup } from 'react-bootstrap';
import CustomField from './customfield';
import reducer from "./reducer";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { watchUsers } from "./saga";
import { Provider } from 'react-redux';
import { reduxForm } from 'redux-form'

const sagaMiddleware = createSagaMiddleware();
const storeFileds = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(watchUsers);

const d = new Date();
const n = d.getTime();

class CustomReduxForm extends React.Component {

  handleButtonClick = () => {
    this.form.reset() // resets "username" field to "admin"
  }


  render() {
  const { items, formName, dataObject, updateAction, className,dynamicDisable, submitted } = this.props;

  return (
        <form id={`form-${formName}`} className={className} key={`form-${formName}`} name={formName}>
          { dataObject && items && items.map((field, j) => {
            field.disabled = dynamicDisable && dynamicDisable[field.name] ? dynamicDisable[field.name] : field.disabled;
              return <CustomField
                              id={j}
                              key={j}
                              label={field.label} 
                              hint={field.hint}
                              type={field.type}
                              typeForm={field.typeForm}
                              options={field.options}
                              dynamicDisable={dynamicDisable}
                              disable={field.disable} 
                              placeholder={field.placeholder}
                              updateAction={updateAction}
                              name={field.name}
                              submitted={submitted}
                              className={field.className}
                          />
          }
        )}
      </form>
  ) 
}
}

export default reduxForm({
  form: `form-state-${n}`, // a unique identifier for this form
})(CustomReduxForm)