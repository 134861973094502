
import TextField from "@material-ui/core/TextField";
import { InputTextarea } from "primereact/inputtextarea";

import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import { RecaptchaEnterpriseServiceClient } from '@google-cloud/recaptcha-enterprise';
import ReCAPTCHA from "react-google-recaptcha";

function TextInputCharacter(props) {
    const [stadoVal, setStateVal] = useState(props.value);

    const onChange = (e) => {
        const newValue = e.target.value;
        const regex = /[^0-9a-zA-ZáéíóúÁÉÍÓÚñÑ?¿,.\s]/g;
        setStateVal(newValue.replace(regex, ''))

    };

    return (

        <InputTextarea
            autoResize
            {...props}
            id={props.name}
            name={props.name}
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={stadoVal}
            onChange={onChange}

        />
    )

}


function OnlyTextInput(props) {
    const [stadoVal, setStateVal] = useState(props.value);

    const onChange = (e) => {
        const newValue = e.target.value;
        if (newValue.match(/^([A-Za-z0-9\s]*)+$/) || newValue.length === 0) {
            setStateVal(newValue); // only set when successful
        }

    };

    return (
        <TextField
            {...props}
            id={props.name}
            name={props.name}
            disabled={props.disabled}
            placeholder={""}
            value={stadoVal}
            onChange={onChange}
        />
    )

}

function AutoCompleteDemo(props) {

    const [countries, setCountries] = useState([]);
    const [selectedCountry2, setSelectedCountry2] = useState("");
    const [filteredCountries, setFilteredCountries] = useState(null);





    useEffect(() => {
        console.log(props.name, props.options)
        setCountries(props.options)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const searchCountry = (event) => {
        setTimeout(() => {
            let _filteredCountries;
            if (!event.query.trim().length) {
                _filteredCountries = [...countries];
            }
            else {
                _filteredCountries = countries.filter((country) => {
                    return country.label.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredCountries(_filteredCountries);
        }, 50);
    }

    return (
        <AutoComplete
            value={selectedCountry2}
            suggestions={filteredCountries}
            completeMethod={searchCountry}
            field="label"
            dropdown
            onChange={(e) => setSelectedCountry2(e.value)}
            aria-label="Countries"
            dropdownAriaLabel={props.placeholder}

            {...props}
            id={props.name}
            name={props.name}
            disabled={props.disabled}
            placeholder={props.placeholder}
        />

    )
}
const recaptchaApiKey = '6LfmltomAAAAAAaCY4ctkzRnUpE0IIv6vWSFhlJ1';

function MyComponentCapcha() {
    const [recaptchaToken, setRecaptchaToken] = useState('');


    useEffect(() => {
        // Configura el cliente de reCAPTCHA Enterprise
        const client = new RecaptchaEnterpriseServiceClient({
            credentials: {
                private_key: recaptchaApiKey,
            },
        });

        // Función para enviar la evaluación de reCAPTCHA y obtener los resultados
        const annotateRecaptcha = async () => {
            const assessment = {
                event: {
                    token: recaptchaToken,
                    siteKey: 'capchaPruebasComfe',
                    expectedAction: 'TU_ACCION_ESPERADA',
                },
            };

            const [result] = await client.annotateAssessment({
                name: `projects/${client.projectId}/assessments/-`,
                assessment,
            });

            console.log('Resultado de la evaluación de reCAPTCHA:', result);
        };

        if (recaptchaToken) {
            annotateRecaptcha();
        }
    }, [recaptchaToken]);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    return (
        <div>
            <ReCAPTCHA
                sitekey="6LfmltomAAAAAAaCY4ctkzRnUpE0IIv6vWSFhlJ1"
                onChange={handleRecaptchaChange}
            />
        </div>
    );
}




export { TextInputCharacter, AutoCompleteDemo, OnlyTextInput, MyComponentCapcha };