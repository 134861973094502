import {
    GET_CONVERSATIONS,
    GET_CONVERSATIONS_ERROR,
    GET_CONVERSATIONS_SUCCESS
} from './constants'

export function getConversations (payload) {
    return {
        type: GET_CONVERSATIONS,
        payload
    };
}

export function getConversationsSuccess (payload) {
    return {
        type: GET_CONVERSATIONS_SUCCESS,
        payload
    };
}

export function getConversationsError (payload) {
    return {
        type: GET_CONVERSATIONS_ERROR,
        payload
    };
}