    import {
        VALIDATE_FIELD,
    } from './constant';
    
    export const initialState = {
        fieldName: false, 
        fieldValue: false,
        submitted: false
    };
    
    const reducer = (state = initialState, action) => {
        const newState = { ...state };
        const { payload, type, value } = action;
    
        switch (type) {
            case VALIDATE_FIELD:
                console.log('reducer.action', action);
            return {
                ...newState
            };
            default:
            return {
                ...newState
            };
        }
    };
    
    export default reducer;
    