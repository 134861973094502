import {
    INIT_ACTION,
    INIT_ACTION_SUCCESS
} from './constants'

export function newAction (payload) {
    return {
        type: INIT_ACTION,
        payload
    };
}

export function newActionSuccess (payload) {
    return {
        type: INIT_ACTION_SUCCESS,
        payload
    };
}