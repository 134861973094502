import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './form.css';
import CustomReduxForm from './customReduxForm';
import CustomButton  from '../custombutton/button';

export default class CustomForm extends Component {
    render() {
        const { items,dynamicDisable, formTitle, className, formName, values, buttons, updateAction, card, submitted, formSubTitle } = this.props;
        
        return (
                card ? 
                <Card key={`card-${formName}`} className={`card-${className}`}>
                    <Card.Header>
                        {formTitle}
                        <p>{formSubTitle}</p>
                    </Card.Header>
                    <Card.Body>
                        <CustomReduxForm 
                            submitted={submitted} 
                            items={items} 
                            formName={formName} 
                            dataObject={values} 
                            updateAction={updateAction} 
                            dynamicDisable={dynamicDisable}
                            />
                        <CustomButton items={buttons} />
                    </Card.Body>
                </Card>
                : <React.Fragment>
                        { formTitle ? 
                            <h2 className="form-title">
                                {formTitle}
                            </h2>
                        : '' }
                        <h3 className="form-sub-title">
                            {formSubTitle}
                        </h3>
                        <CustomReduxForm submitted={submitted} key={formName} className={`form-${className}`} items={items} formName={formName} dataObject={values} updateAction={updateAction} />
                        <CustomButton items={buttons} />
                </React.Fragment>
        );
    }
}
